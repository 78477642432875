import { Resolution, Margin } from "react-to-pdf";
import { tInputModes, tSelectLabelValue } from "../types";
import haulTruckTemplate from "../forms/haul-truck-template.json"
import excavatorTemplate from "../forms/excavator-template.json"
import impactCrusherTemplate from "../forms/impact-crusher-template.json"
import pitYardTemplate from "../forms/pit-yard-template.json"
import lightTowerTemplate from "../forms/light-tower-template.json"

/**
 * FULL SUBSCRIPTION:
 * NOTE:  Add your user.userId (cognito ID) to this array to gain access to Company admin page at `/admin`
 * 999909ee-4071-706d-1f39-d0b4bba186bc --> mc+test-super-admin@mc.dev in MAIN environment
* a21e01b8-877d-4898-88f7-e199b1265219 --> mc+test-super-admin@mc.dev in DEVELOP environment
 * 5949692e-40d1-7064-c8d5-f91718b2d11b --> mc+prod-super-admin@mc.dev in MAIN environment
 * b909d90e-5051-706e-8ba9-ba2670fd09ae --> mstratton05@gmail.com in MAIN environment
  * a37fe9bb-b655-4b18-b38a-b79cb315d31c --> mariah.wear@equipmentinspectionforms.com in DEVELOP environment
 * f8009c98-45cb-4552-ad4a-a654ce7b23d9 --> cc+superadmin@equipmentinspectionfroms.com in DEVELOP environment
 * b959d98e-5061-709a-614d-59915d9581ba --> mw+sa-mariah@euipmentinspectionforms.com in MARIAH environment
 * 593939ee-8071-70ec-673e-ddfb99c10c13 --> mc+test-super-admin@mc.dev in MARK environment
 * 89cc3978-7ba1-4a62-83bb-8095876efc66 --> mw+sa-develop@equipmentinspectionforms.com in DEVELOP environment
 * 
 * 
 * FREE_DEMO: -- these can be moved into FULL SUBSCRIPTION if user upgrades
 * 4919994e-f041-70ae-3fb8-ab4402aebb90 --> mariah+demo-test@equipmentinspectionforms.comin MARIAH environment
 */

export const SUPER_ADMIN_USER_IDS = [
    "999909ee-4071-706d-1f39-d0b4bba186bc",
    "a21e01b8-877d-4898-88f7-e199b1265219",
    "5949692e-40d1-7064-c8d5-f91718b2d11b",
    "b909d90e-5051-706e-8ba9-ba2670fd09ae",
    "a37fe9bb-b655-4b18-b38a-b79cb315d31c",
    "f8009c98-45cb-4552-ad4a-a654ce7b23d9",
    "b959d98e-5061-709a-614d-59915d9581ba",
    "593939ee-8071-70ec-673e-ddfb99c10c13",
    "89cc3978-7ba1-4a62-83bb-8095876efc66"
];

export const FREE_DEMO_USER_IDS = [
    "4919994e-f041-70ae-3fb8-ab4402aebb90"
]

export enum PERMISSION_VALUES {
    INSPECT = "inspect",
    VIEW_REPORTS = "view-reports",
    UPDATE_DISCREPANCIES = "update-discrepancies",
    VIEW_DISCREPANCIES = "view-discrepancies",
    ARCHIVE_DISCREPANCIES = "archive-discrepancies",
    MANAGE_EQUIPMENT = "manage-equipment",
    MANAGE_FORMS = "manage-forms",
    MANAGE_USERS = "manage-users",
    SUPER_ADMIN = "super-admin",
}

export const DEFAULT_USER_PERMISSIONS = [
    {
        label: 'Conduct Inspections',
        value: PERMISSION_VALUES.INSPECT,
    },
    {
        label: 'View Reports',
        value: PERMISSION_VALUES.VIEW_REPORTS,
    },
];

export const ALL_USER_PERMISSIONS = [
    // use `devOnly: true` to mark objects for testing and keep them from appearing in PROD
    {
        label: 'Inspector',
        options: [
            {
                label: 'Conduct Inspections',
                value: PERMISSION_VALUES.INSPECT,
            },
            {
                label: 'View Reports',
                value: PERMISSION_VALUES.VIEW_REPORTS,
            },
        ],
    },
    {
        devOnly: false,
        label: 'Service Technician',
        options: [
            {
                label: 'Update Issues',
                value: PERMISSION_VALUES.UPDATE_DISCREPANCIES,
            },
            {
                label: 'View Issues',
                value: PERMISSION_VALUES.VIEW_DISCREPANCIES,
            },
            {
                label: 'Archive Issues',
                value: PERMISSION_VALUES.ARCHIVE_DISCREPANCIES,
            },
        ],
    },
    {
        label: 'Supervisor',
        options: [
            {
                label: 'Manage Equipment',
                value: PERMISSION_VALUES.MANAGE_EQUIPMENT,
            },
            {
                label: 'Manage Forms',
                value: PERMISSION_VALUES.MANAGE_FORMS,
            },
            {
                label: 'Manage Users',
                value: PERMISSION_VALUES.MANAGE_USERS,
            },
        ],
    },
];

export const SUPER_ADMIN_USER_PERMISSIONS = [
    // use `devOnly: true` to mark objects for testing and keep them from appearing in PROD

    {
        label: 'Super Admin',
        options: [
            {
                label: 'Super Admin',
                value: PERMISSION_VALUES.SUPER_ADMIN,
            },
        ],
    }, {
        label: 'Inspector',
        options: [
            {
                label: 'Conduct Inspections',
                value: PERMISSION_VALUES.INSPECT,
            },
            {
                label: 'View Reports',
                value: PERMISSION_VALUES.VIEW_REPORTS,
            },
        ],
    },
    {
        devOnly: false,
        label: 'Service Technician',
        options: [
            {
                label: 'Update Issues',
                value: PERMISSION_VALUES.UPDATE_DISCREPANCIES,
            },
            {
                label: 'View Issues',
                value: PERMISSION_VALUES.VIEW_DISCREPANCIES,
            },
            {
                label: 'Archive Issues',
                value: PERMISSION_VALUES.ARCHIVE_DISCREPANCIES,
            },
        ],
    },
    {
        label: 'Supervisor',
        options: [
            {
                label: 'Manage Equipment',
                value: PERMISSION_VALUES.MANAGE_EQUIPMENT,
            },
            {
                label: 'Manage Forms',
                value: PERMISSION_VALUES.MANAGE_FORMS,
            },
            {
                label: 'Manage Users',
                value: PERMISSION_VALUES.MANAGE_USERS,
            },
        ],
    },
];

export const DEFAULT_ADMIN_PERMISSIONS = ALL_USER_PERMISSIONS.flatMap(role => role.options) // When a SUPER ADMIN adds a user, we want that user to have ALL PERMISSIONS by default

export const SUBCRIPTION_TYPE_AND_DESCRIPTION = [
    { value: "free-demo", label: "Free Demo", description: "This is the basic free trial with limited access to the application." },
    { value: "paid-limited-access", label: "Limited Subscription", description: "Paid subscription with limits to users and equipment" },
    { value: "paid-full-access", label: "Full Subscription", description: "This is the full access subscription." },
    { value: "paused", label: "Pause", description: "If an account is behind on payment pause until balance is paid." }
];

export const ROLES = {
    ADMIN: "admin",
    INSPECTOR: "inspector",
    FOREMAN: "foreman",
    SERVICE_TECHNICIAN: "service-technician",
    SUPER_ADMIN: "super-admin",
};

export const RESET_EQUIPMENT_UNIT = {
    id: null,
    name: '',
    machineHours: 0,
    lastServiceHours: 0,
};

export const NETWORK_DOMAIN = `${window.location.protocol}//${window.location.host}`;

export const MAX_NUMBER_OF_PHOTOS_PER_PROMPT = 3;
export const WEBCAM_DIMENSIONS = {
    height: 500,
    width: 330,
};

export const LOCAL_STORAGE_KEYS = {
    QR_CLASSIFICATION_DEFAULT: 'QRClassification',
    CAMERA: 'camera',
    EQUIPMENT_NAME: 'equipmentName',
    LOCATION: 'location',
    REPORT_TO_SEND: 'reportToSend',
    REPORT: 'report',
    INSPECT: 'inspect',
};

export const LOCATION_TYPES = {
    gps: 'Device GPS',
    lastKnownPosition: 'Last Known Position',
    unknown: 'Unknown',
    ip: 'IP Address',
};

export const DEFAULT_REPORT_LABELS = {
    machineHours: 'Machine Hours',
    lastServiceHours: 'Last Service Hours',
    timeSinceLastService: 'Time Since Last Service',
};

export const DEFAULT_FORM_INPUT_TYPES = {
    question: 'question',
    section: 'section',
};

export enum SEARCH_PARAMS {
    SETTINGS = 'settings',
    EMAIL = 'email',
    EMPTY = '',
}

export const PDF_OPTIONS = {
    // default is `save`
    method: 'save',
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.MEDIUM,
    page: {
        // margin is in MM, default is Margin.NONE = 0
        margin: Margin.SMALL,
        // default is 'A4'
        format: 'letter',
        // default is 'portrait'
        orientation: 'portrait',
    },
    canvas: {
        // default is 'image/jpeg' for better size performance
        mimeType: 'image/jpeg',
        qualityRatio: 1,
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break,
    // so use with caution.
    overrides: {
        // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
        pdf: {
            compress: true,
        },
        // see https://html2canvas.hertzen.com/configuration for more options
        canvas: {
            useCORS: true,
        },
    },
};

export const REPORT_SENDING_STATE = {
    CHECKING_NETWORK: 'Checking Network',
    REPORT_SENT: 'Report Sent',
    REPORT_SENDING: 'Report Sending',
    REPORT_ERROR: 'Error - Report not sent: ',
    OFFLINE: 'Offline - Report is stored and will be sent when the connection is restored.',
};

export const ITEM_STATUS_CODES = {
    inop: 'inop',
    warn: 'warn',
    ok: 'ok',
    na: 'na',
};

export enum ITEM_STATUS_CONTENT {
    inop = 'Out Of Service',
    warn = 'Warning',
    ok = 'OK',
    resolved = 'Resolved',
    no_change = 'No Change',
}

export const ALL_EQUIPMENT: tSelectLabelValue = {
    label: "All Equipment",
    value: "all",
};

export const INPUT_MODES: tInputModes = {
    TEXT: "text",
    NUMBER: "decimal",
    TEXTAREA: "textarea",
};

export const ADMIN_DASHBOARD_ELEMENTS = [
    {
        text: 'Forms',
        href: '/forms',
        icon: 'forms_icon.jpg',
    },
    {
        text: 'Equipment',
        href: '/equipment',
        icon: 'equipment_icon.jpg',
    },
    {
        text: 'Users',
        href: '/users',
        icon: 'users_icon.jpg',
    },
    {
        text: 'Reports',
        href: '/reports',
        icon: 'reports_icon.png',
    },
];

export const INSPECTOR_DASHBOARD_ELEMENTS = ADMIN_DASHBOARD_ELEMENTS.filter((obj) => obj.text !== 'Users');

export const INSPECTOR_DASHBOARD_LINKS = INSPECTOR_DASHBOARD_ELEMENTS.map((element) => ({
    ...element,
    type: 'link',
}));

export const ADMIN_DASHBOARD_LINKS = ADMIN_DASHBOARD_ELEMENTS.map((element) => ({
    ...element,
    type: 'link',
}));

export const DASHBOARD_CARD_ELEMENTS = [
    {
        text: 'Users',
        permissions: PERMISSION_VALUES.MANAGE_USERS,
        description: 'Click to manage users within the company (add, edit, or delete)',
        href: '/company/users',
        icon: 'users_icon.jpg',
    },
    {
        text: "Forms",
        permissions: PERMISSION_VALUES.MANAGE_FORMS,
        description: 'Click to manage and create forms (inspection reports or service reports)',
        href: '/company/forms',
        icon: 'forms_icon.jpg',
    },
    {
        text: 'Equipment',
        permissions: PERMISSION_VALUES.MANAGE_EQUIPMENT,
        description: 'Click to manage, add, delete equipment attributed to the company',
        href: '/company/equipment',
        icon: 'equipment_icon.jpg',
    },
    {
        text: 'Reports',
        permissions: PERMISSION_VALUES.VIEW_REPORTS,
        description: 'Click to manage reports, viewing details, checking equipment status',
        href: '/company/reports',
        icon: 'reporting-image.png',
    },
    {
        text: 'Issues',
        permissions: PERMISSION_VALUES.VIEW_DISCREPANCIES,
        description: 'Click to manage and view items with warning or inoperative statuses',
        href: '/company/issues',
        icon: 'discrepancy_icon.jpg',
    },
];

export const MAX_NUMBER_USERS_FREE_DEMO = 4
export const MAX_NUMBER_EQUIPMENT_FREE_DEMO = 4
export const MAX_NUMBER_FORMS_FREE_DEMO = 4
export const MAX_NUMBER_COMPANY_FREE_DEMO = 1

export const FORM_TEMPLATE_ITEMS = [
    {
        id: "excavator",
        text: 'Excavator',
        template: excavatorTemplate,
    },
    {
        id: "haul-truck",
        text: 'Haul Truck',
        template: haulTruckTemplate,
    },
    {
        id: "impact-crusher",
        text: 'Impact Crusher',
        template: impactCrusherTemplate,
    },
    {
        id: "light-tower",
        text: 'Light Tower',
        template: lightTowerTemplate,
    },
    {
        id: "pit-yard",
        text: 'Pit/Yard',
        template: pitYardTemplate,
    }
]

export const FREE_DEMO_FORM_TEMPLATE_ITEMS = [
    {
        id: "excavator",
        text: 'Excavator',
        template: excavatorTemplate,
    },
    {
        id: "haul-truck",
        text: 'Haul Truck',
        template: haulTruckTemplate,
    },
    {
        id: "pit-yard",
        text: 'Pit/Yard',
        template: pitYardTemplate,
    }
]


