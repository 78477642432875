import { Key, useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { generateClient } from 'aws-amplify/api';
import { useUserContext } from '../contexts/UserContext';
import {
    equipmentByCompanyId as equipmentByCompanyIdQuery,
    archivedDiscrepanciesByCompanyIdAndUpdatedAt,
    discrepanciesByCompanyIdAndUpdatedAt as discrepanciesByCompanyIdAndUpdatedAtQuery,
    archivedDiscrepanciesByEquipmentIdAndUpdatedAt,
} from '../graphql/queries';

import { createArchivedDiscrepancy as createArchivedDiscrepancyMutation, updateDiscrepancy as updateDiscrepancyMutation, deleteDiscrepancy as deleteDiscrepancyMutation } from '../graphql/mutations';

import { ArchivedDiscrepancy, CreateArchivedDiscrepancyInput, Discrepancy, Equipment, ModelSortDirection, Unit } from '../API';
import { tDiscrepancyLog, tSelectLabelValue } from '../types';
import { Badge, Box, Button, Checkbox, Container, FormField, Header, Modal, RadioGroup, Select, SpaceBetween, Table, Textarea } from '@cloudscape-design/components';
import { ALL_EQUIPMENT, ITEM_STATUS_CODES, ITEM_STATUS_CONTENT, PERMISSION_VALUES, SEARCH_PARAMS } from '../utils/constants';
import { userHasExplicitPermission } from '../utils/helperFunctions';
import { useCompanyContext } from '../contexts/CompanyContext';

const client = generateClient({ authMode: 'userPool' });

export const Issues = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [discrepancies, setDiscrepancies] = useState<Discrepancy[]>([]);
    const [discrepancyIdToUpdate, setDiscrepancyToUpdate] = useState<Discrepancy | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [archivedDiscrepanciesList, setArchivedDiscrepanciesList] = useState<ArchivedDiscrepancy[] | []>([]);
    const [selectedEquipment, setSelectedEquipment] = useState<tSelectLabelValue>(ALL_EQUIPMENT);
    const [selectedUnit, setSelectedUnit] = useState<tSelectLabelValue | null>(null);
    const [equipmentById, setEquipmentById] = useState<Record<string, Equipment | null>>({});
    const [equipmentList, setEquipmentList] = useState<Equipment[] | []>([]);

    const [updateStatus, setUpdateStatus] = useState<string | null>(null);
    const [itemToArchive, setItemToArchive] = useState<CreateArchivedDiscrepancyInput | null>(null);
    const [shouldShowUpdateNotificationsModal, setShouldShowUpdateNotificationsModal] = useState<boolean>(searchParams.get(SEARCH_PARAMS.SETTINGS) === SEARCH_PARAMS.EMAIL)
    const [updateNotes, setUpdateNotes] = useState('');
    const [isUpdating, setIsUpdating] = useState<string | null>(null);
    const [isArchiving, setIsArchiving] = useState<string | null>(null);
    const { company, companyUser, newDiscrepancyNotificationTypes, setNewDiscrepancyNotificationTypes, setShouldUpdateUser } = useUserContext();
    const { company: companyContext } = useCompanyContext();

    const companyId = company?.id as string || companyContext?.id as string


    const getDiscrepancies = async () => {
        if (!company?.id && !companyContext) return;
        const result = await client.graphql({
            query: discrepanciesByCompanyIdAndUpdatedAtQuery,
            variables: {
                companyId: companyId,
                sortDirection: 'DESC' as ModelSortDirection,
            },
        });
        setDiscrepancies(
            result.data.discrepanciesByCompanyIdAndUpdatedAt.items.sort((item) =>
                item.status === ITEM_STATUS_CODES.inop ? -1 : 1,
            ),
        );
    };

    const fetchCompanyEquipment = async () => {
        if (!company?.id && !companyContext?.id) {
            return
        }
        setIsLoading(true);
        try {
            const equipmentResult = await client.graphql({
                query: equipmentByCompanyIdQuery,
                variables: {
                    companyId: companyId,
                },
            });
            setEquipmentList(equipmentResult.data.equipmentByCompanyId.items);
            const equipObj: Record<string, Equipment> = {};
            equipmentResult.data.equipmentByCompanyId.items.forEach(
                (item) => (equipObj[item.id] = { ...item })
            );
            setEquipmentById(equipObj);
        } catch (error) {
            console.error("Error fetching data:", error);
            Sentry.captureException(error);
        } finally {
            setIsLoading(false);
        }
    };
    const getDiscrepanciesListByDate = async () => {
        if (!company?.id && !companyContext?.id) {
            return
        }
        setIsLoading(true);
        try {
            const result = await client.graphql({
                query: archivedDiscrepanciesByCompanyIdAndUpdatedAt,
                variables: {
                    companyId: companyId,
                    sortDirection: "DESC" as ModelSortDirection,
                },
            });
            setArchivedDiscrepanciesList(result.data.archivedDiscrepanciesByCompanyIdAndUpdatedAt.items);
        } catch (error) {
            console.log(error);
            Sentry.captureException(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getDiscrepanciesListByDate();
        fetchCompanyEquipment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const getArchivedDiscrepanciesList = async () => {
            setIsLoading(true);
            try {
                if (selectedEquipment.value === ALL_EQUIPMENT.value) {
                    const result = await client.graphql({
                        query: archivedDiscrepanciesByCompanyIdAndUpdatedAt,
                        variables: {
                            companyId: companyId || '',
                            sortDirection: "DESC" as ModelSortDirection,
                        },
                    })
                    setArchivedDiscrepanciesList(
                        result.data.archivedDiscrepanciesByCompanyIdAndUpdatedAt?.items
                    );
                } else {
                    const result = await client.graphql({
                        query: archivedDiscrepanciesByEquipmentIdAndUpdatedAt,
                        variables: {
                            equipmentId: selectedEquipment.value,
                            sortDirection: "DESC" as ModelSortDirection,
                        },
                    });
                    setArchivedDiscrepanciesList(
                        result.data.archivedDiscrepanciesByEquipmentIdAndUpdatedAt?.items
                    );
                }
            } catch (error) {
                console.log(error);
                Sentry.captureException(error);
            } finally {
                setIsLoading(false);
            }
        };
        if (selectedEquipment) {
            getArchivedDiscrepanciesList();
        }
    }, [company?.id, companyContext?.id, selectedEquipment]);

    const hasUnitsInEquipment = (equipment: tSelectLabelValue): (Unit | null)[] => {
        const equipmenWithUnits = equipmentList.find(
            (item) => item.id === equipment?.value
        );
        return (
            (equipmenWithUnits?.equipment?.length && equipmenWithUnits?.equipment?.length > 0 && equipmenWithUnits.equipment) || []
        );
    };

    useEffect(() => {
        if (company?.id || companyContext?.id) {
            getDiscrepancies();
            // getArchivedDiscrepancies()
        }
    }, []);

    useEffect(() => {
        const archiveItem = async () => {
            if (!itemToArchive) return;
            setIsArchiving(itemToArchive.id || '');
            const archivedInput = {
                ...itemToArchive as Discrepancy,
                id: undefined,
                __typename: undefined,
                owner: undefined,
            } as CreateArchivedDiscrepancyInput;
            try {
                await client.graphql({
                    query: createArchivedDiscrepancyMutation,
                    variables: {
                        input: archivedInput,
                    },
                });
                if (itemToArchive.id) {
                    await client.graphql({
                        query: deleteDiscrepancyMutation,
                        variables: {
                            input: {
                                id: itemToArchive.id,
                            },
                        },
                    })
                }
            } catch (error) {
                console.log(error);
                Sentry.captureException(error);
            } finally {
                setIsArchiving(null);
                setItemToArchive(null);
                getDiscrepancies();
            }
        }
        archiveItem()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemToArchive])

    const updateDiscrepancy = async () => {
        if (!discrepancyIdToUpdate) return;
        setIsUpdating(discrepancyIdToUpdate.id);
        try {
            await client.graphql({
                query: updateDiscrepancyMutation,
                variables: {
                    input: {
                        id: discrepancyIdToUpdate.id,
                        status: updateStatus || discrepancyIdToUpdate.status,
                        log: JSON.stringify([
                            ...JSON.parse(discrepancyIdToUpdate.log || '[]'),
                            {
                                name: companyUser?.name,
                                notes: `${updateNotes}${updateStatus ? ` [Updated Status: ${ITEM_STATUS_CONTENT[updateStatus as keyof typeof ITEM_STATUS_CONTENT]}] ` : ''}`,
                                date: new Date().toISOString(),
                            },
                        ]),
                    },
                },
            });
        } catch (error) {
            console.log(error);
            Sentry.captureException(error);
        } finally {
            setIsUpdating(null);
            setUpdateNotes('');
            setUpdateStatus(null);
            setDiscrepancyToUpdate(null);
            getDiscrepancies();
        }
    }

    const getStatusOptions = () => {
        return discrepancyIdToUpdate?.status === ITEM_STATUS_CODES.warn ? [
            { value: '', label: ITEM_STATUS_CONTENT.no_change },
            { value: ITEM_STATUS_CODES.ok, label: `Mark as ${ITEM_STATUS_CONTENT.resolved}` }
        ] : [
            { value: '', label: ITEM_STATUS_CONTENT.no_change },
            { value: ITEM_STATUS_CODES.warn, label: `Mark as ${ITEM_STATUS_CONTENT.warn}` },
            { value: ITEM_STATUS_CODES.ok, label: `Mark as ${ITEM_STATUS_CONTENT.resolved}` }
        ]
    }

    const getBadgeColor = (status: string) => {
        switch (status) {
            case ITEM_STATUS_CODES.inop:
                return 'red';
            case ITEM_STATUS_CODES.warn:
                return 'grey';
            default:
                return 'green';
        }
    }

    const getBadgeContent = (status: string) => {
        switch (status) {
            case ITEM_STATUS_CODES.inop:
                return ITEM_STATUS_CONTENT.inop;
            case ITEM_STATUS_CODES.warn:
                return ITEM_STATUS_CONTENT.warn;
            default:
                return ITEM_STATUS_CONTENT.resolved;
        }
    }

    return (
        <>
            <SpaceBetween direction="vertical" size="xl">
                <Container>
                    <div className='viewInMobile'>
                        <Table

                            header={<Header actions={<Button onClick={() => setShouldShowUpdateNotificationsModal(true)}>Email Notifications: {JSON.parse(companyUser?.discrepancyNotificationTypes || '{}')?.warn || JSON.parse(companyUser?.discrepancyNotificationTypes || '{}')?.inop ? 'On' : 'Off'}</Button>} counter={`(${discrepancies.length.toString()})`}>Issues</Header>}
                            columnDefinitions={[
                                {
                                    id: 'name',
                                    header: 'Equipment & Status',
                                    cell: (item) => (
                                        <>
                                            <Box variant="span" fontWeight="bold">
                                                {item.equipmentName}
                                            </Box>
                                            <Box>
                                                {' -- '}
                                            </Box>
                                            <Badge color={getBadgeColor(item.status)}>
                                                {getBadgeContent(item.status)}
                                            </Badge>
                                        </>)
                                },
                                {
                                    id: 'description',
                                    header: 'Log',
                                    cell: (item) =>
                                        JSON.parse(item.log || '')?.map((logEntry: tDiscrepancyLog, idx: Key | null | undefined) => {
                                            return (
                                                <Box margin={{ bottom: 'l', top: 'l' }}>
                                                    <Box variant="p" key={idx}>
                                                        {logEntry.notes}
                                                    </Box>
                                                    <Box variant="span" fontWeight="bold">
                                                        {logEntry.name}
                                                    </Box>
                                                    <br />
                                                    <Box variant="small">{`${new Date(logEntry.date).toLocaleDateString()} ${new Date(logEntry.date).toLocaleTimeString()}`}</Box>
                                                </Box>
                                            );
                                        }),
                                },
                                {
                                    id: 'update',
                                    header: 'Update',
                                    cell: (item) => <Button loading={isUpdating === item.id} variant="icon" iconName="edit" onClick={() => {
                                        setDiscrepancyToUpdate(item)
                                    }}>Update</Button>,
                                },
                                {
                                    id: 'archive',
                                    header: 'Archive',
                                    cell: (item) => item.status === ITEM_STATUS_CODES.ok ? <Button loading={isArchiving === item.id} variant="icon" iconName="remove" onClick={() => {
                                        setItemToArchive(item)
                                    }}>Archive</Button> : null,
                                }
                            ]}
                            items={discrepancies}
                            wrapLines
                            contentDensity='compact'
                            // variant="full-page"
                            empty={
                                <Box textAlign="center" color="inherit">
                                    <b>No issues</b>
                                </Box>}
                            columnDisplay={[
                                {
                                    id: 'name',
                                    visible: true,
                                },
                                {
                                    id: 'description',
                                    visible: true,
                                },
                                {
                                    id: 'update',
                                    visible: userHasExplicitPermission(companyUser, PERMISSION_VALUES.UPDATE_DISCREPANCIES),
                                },
                                {
                                    id: 'archive',
                                    visible: userHasExplicitPermission(companyUser, PERMISSION_VALUES.ARCHIVE_DISCREPANCIES),
                                },
                            ]}
                        />
                    </div>
                    <div className='viewInDesktop'>
                        <Table

                            header={<Header actions={<Button onClick={() => setShouldShowUpdateNotificationsModal(true)}>Email Notifications: {JSON.parse(companyUser?.discrepancyNotificationTypes || '{}')?.warn || JSON.parse(companyUser?.discrepancyNotificationTypes || '{}')?.inop ? 'On' : 'Off'}</Button>} counter={`(${discrepancies.length.toString()})`}>Current Issues</Header>}
                            columnDefinitions={[
                                {
                                    id: 'name',
                                    header: 'Equipment',
                                    cell: (item) => item.equipmentName,
                                },
                                {
                                    id: 'status',
                                    header: 'Status',
                                    cell: (item) => (
                                        <Badge color={getBadgeColor(item.status)}>
                                            {getBadgeContent(item.status)}
                                        </Badge>
                                    ),
                                },
                                {
                                    id: 'description',
                                    header: 'Log',
                                    cell: (item) =>
                                        JSON.parse(item.log || '')?.map((logEntry: tDiscrepancyLog, idx: Key | null | undefined) => {
                                            return (
                                                <Box margin={{ bottom: 'l', top: 'l' }}>
                                                    <Box variant="p" key={idx}>
                                                        {logEntry.notes}
                                                    </Box>
                                                    <Box variant="span" fontWeight="bold">
                                                        {logEntry.name}{' - '}
                                                    </Box>
                                                    <Box variant="span">{`${new Date(logEntry.date).toLocaleDateString()} ${new Date(logEntry.date).toLocaleTimeString()}`}</Box>
                                                </Box>
                                            );
                                        }),
                                },
                                {
                                    id: 'update',
                                    header: 'Update',
                                    cell: (item) => <Button loading={isUpdating === item.id} variant="icon" iconName="edit" onClick={() => {
                                        setDiscrepancyToUpdate(item)
                                    }}>Update</Button>,
                                },
                                {
                                    id: 'archive',
                                    header: 'Archive',
                                    cell: (item) => item.status === ITEM_STATUS_CODES.ok ? <Button loading={isArchiving === item.id} variant="icon" iconName="remove" onClick={() => {
                                        setItemToArchive(item)
                                    }}>Archive</Button> : null,
                                }
                            ]}
                            items={discrepancies}
                            wrapLines
                            contentDensity='compact'
                            // variant="full-page"
                            empty={
                                <Box textAlign="center" color="inherit">
                                    <b>No issues</b>
                                </Box>}
                            columnDisplay={[
                                {
                                    id: 'name',
                                    visible: true,
                                },
                                {
                                    id: 'status',
                                    visible: true,
                                },
                                {
                                    id: 'description',
                                    visible: true,
                                },
                                {
                                    id: 'update',
                                    visible: userHasExplicitPermission(companyUser, PERMISSION_VALUES.UPDATE_DISCREPANCIES),
                                },
                                {
                                    id: 'archive',
                                    visible: userHasExplicitPermission(companyUser, PERMISSION_VALUES.ARCHIVE_DISCREPANCIES),
                                },
                            ]}
                        />
                    </div>
                    {/* Archived Discrepancies Table */}
                </Container>
                <Container>
                    <SpaceBetween direction='vertical' size='m'>

                        <Header>Filter by Equipment</Header>
                        <Select
                            onChange={({ detail }) => {
                                setArchivedDiscrepanciesList([]);
                                setSelectedEquipment(detail.selectedOption as tSelectLabelValue);
                                setSelectedUnit(null);
                            }}
                            options={[
                                ALL_EQUIPMENT,
                                ...equipmentList.map((equipment) => ({
                                    label: equipment.name,
                                    value: equipment.id,
                                })),
                            ]}
                            selectedOption={selectedEquipment}
                        />
                        <Select
                            onChange={({ detail }) => {
                                setSelectedUnit(detail.selectedOption as tSelectLabelValue);
                            }}
                            options={
                                hasUnitsInEquipment(selectedEquipment).length
                                    ? [
                                        { label: `All Units`, value: '' },
                                        ...hasUnitsInEquipment(selectedEquipment).map((unit) => ({
                                            label: unit?.name,
                                            value: unit?.id,
                                        })),
                                    ]
                                    : []
                            }
                            placeholder="Select a unit"
                            empty="No units"
                            selectedOption={selectedUnit}
                        />
                        <Table

                            header={<Header counter={`(${archivedDiscrepanciesList.length.toString()})`}>Archived Issues</Header>}
                            columnDefinitions={[
                                {
                                    id: 'name',
                                    header: 'Equipment',
                                    cell: (item) => item.equipmentName,
                                },
                                {
                                    id: 'status',
                                    header: 'Status',
                                    cell: (item) => (
                                        <Badge color={getBadgeColor(item.status)}>
                                            {getBadgeContent(item.status)}
                                        </Badge>
                                    ),
                                },
                                {
                                    id: 'description',
                                    header: 'Log',
                                    cell: (item) =>
                                        JSON.parse(item.log || '')?.map((logEntry: tDiscrepancyLog, idx: Key | null | undefined) => {
                                            return (
                                                <Box margin={{ bottom: 'l', top: 'l' }}>
                                                    <Box variant="p" key={idx}>
                                                        {logEntry.notes}
                                                    </Box>
                                                    <Box variant="span" fontWeight="bold">
                                                        {logEntry.name}{' - '}
                                                    </Box>
                                                    <Box variant="span">{`${new Date(logEntry.date).toLocaleDateString()} ${new Date(logEntry.date).toLocaleTimeString()}`}</Box>
                                                </Box>
                                            );
                                        }),
                                },
                            ]}
                            items={
                                selectedUnit && selectedUnit.value !== ''
                                    ? archivedDiscrepanciesList.filter((item) => item.unitId === selectedUnit.value)
                                    : archivedDiscrepanciesList
                            }
                            wrapLines
                            contentDensity='compact'
                            // variant="full-page"
                            empty={
                                <Box textAlign="center" color="inherit">
                                    <b>No issues</b>
                                </Box>}
                            columnDisplay={[
                                {
                                    id: 'name',
                                    visible: true,
                                },
                                {
                                    id: 'status',
                                    visible: true,
                                },
                                {
                                    id: 'description',
                                    visible: true,
                                },
                            ]}
                        />
                    </SpaceBetween>
                </Container>
                <Modal visible={!!discrepancyIdToUpdate} onDismiss={() => setDiscrepancyToUpdate(null)} footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setDiscrepancyToUpdate(null)}>Cancel</Button>
                            <Button variant="primary" onClick={() => updateDiscrepancy()}>Update</Button>
                        </SpaceBetween>
                    </Box>
                }
                    header={<Header>Update {discrepancyIdToUpdate?.equipmentName}</Header>}
                >
                    <RadioGroup
                        onChange={({ detail }) => setUpdateStatus(detail.value.length ? detail.value : null)}
                        value={updateStatus as unknown as string || ''}
                        items={getStatusOptions()}
                    />
                    <FormField
                        label="Notes"
                    >
                        <Textarea
                            onChange={({ detail }) => setUpdateNotes(detail.value)}
                            value={updateNotes}
                            placeholder="Add a note"
                        />
                    </FormField>
                </Modal>
                <Modal visible={shouldShowUpdateNotificationsModal} onDismiss={() => {
                    setSearchParams(SEARCH_PARAMS.EMPTY);
                    setShouldShowUpdateNotificationsModal(false)
                }} header={<Header description="Notifications are sent when reports containing issues are submitted.">Receive Issues Email Notifications</Header>} footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => {
                                setSearchParams(SEARCH_PARAMS.EMPTY);
                                setShouldShowUpdateNotificationsModal(false)

                            }}>Close</Button>
                            <Button variant="primary" onClick={() => {
                                setShouldUpdateUser(true);
                                setSearchParams(SEARCH_PARAMS.EMPTY);
                                setShouldShowUpdateNotificationsModal(false);
                            }
                            }>Save</Button>
                        </SpaceBetween>
                    </Box>
                }>
                    <FormField>
                        <Checkbox onChange={({ detail }) => {
                            const existingNotificationTypes = JSON.parse(newDiscrepancyNotificationTypes || '{}');
                            setNewDiscrepancyNotificationTypes(JSON.stringify({
                                ...existingNotificationTypes,
                                inop: detail.checked
                            }));
                        }}
                            checked={JSON.parse(newDiscrepancyNotificationTypes)?.inop}>Out Of Service Notifications</Checkbox>
                    </FormField>
                    <FormField>
                        <Checkbox onChange={({ detail }) => {
                            const existingNotificationTypes = JSON.parse(newDiscrepancyNotificationTypes || '{}');
                            setNewDiscrepancyNotificationTypes(JSON.stringify({
                                ...existingNotificationTypes,
                                warn: detail.checked
                            }));
                        }}
                            checked={JSON.parse(newDiscrepancyNotificationTypes)?.warn}>Warning Notifications</Checkbox>
                    </FormField>
                </Modal>
            </SpaceBetween>
        </>
    );
};
