import { Box, Container, Header, SpaceBetween } from "@cloudscape-design/components";
import { Button as AWSButton, Flex } from "@aws-amplify/ui-react"
import { useNavigate } from 'react-router-dom';


export const PricingInfo = (): JSX.Element => {
    const navigate = useNavigate()


    return (
        <>


            <Flex direction="column" gap="xxl" style={{ position: 'absolute', top: "20%", left: "50%", transform: "translateX(-50%)" }}>
                <Container>
                    <SpaceBetween size="m">
                        <Box >
                            <Header variant="h1">Package Pricing</Header>
                            <br />
                            Please request information about one time signup fee for software access and subscription tiers
                        </Box>
                        <Box >
                            <Header variant="h3">Contact Info</Header>
                            Mark Stratton
                            <br />
                            Email:
                            <a href="mailto:markstratton05@gmail.com">{" "}markstratton05@gmail.com</a>
                            <br />
                            Phone:
                            <a href="tel:+14326613606">{" "}(432) 661-3606</a>
                        </Box>
                    </SpaceBetween>
                </Container >
                <AWSButton
                    loadingText=""
                    backgroundColor="gray"
                    onClick={() => navigate("/", { replace: true })}
                >
                    Back
                </AWSButton>
            </Flex>
        </>
    )
};
