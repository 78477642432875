import { useEffect } from "react";
import { Image, View, Flex } from '@aws-amplify/ui-react';
// import { signUp, SignUpInput } from 'aws-amplify/auth';
import { Authenticator, useAuthenticator, Button as AWSButton } from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from "react-router";
import { SignUpHeader } from "../components/Auth/SignUpHeader/SignUpHeader";
import { generateClient } from "aws-amplify/api";
import { CreateCompanyOwnerInput } from "../API";
// import { DEFAULT_ADMIN_PERMISSIONS, NETWORK_DOMAIN, ROLES } from "../utils/constants";
import { createCompanyOwner } from "../graphql/mutations";
import { Hub } from "aws-amplify/utils";
import { ALL_USER_PERMISSIONS, SUPER_ADMIN_USER_PERMISSIONS } from "../utils/constants";
import { AuthUser, fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";
const client = generateClient({ authMode: "userPool" });


export const FreeDemoSignUp = () => {
    // const { route } = useAuthenticator((context) => [context.route]);
    const { user, route } = useAuthenticator((context) => [
        context.route,
    ]);
    const navigate = useNavigate();


    useEffect(() => {

        const updateUserWithCognitoId = async () => {
            try {
                const attributes = await fetchUserAttributes();
                const email = attributes.email as string
                const name = attributes.name as string
                const cognitoId = attributes.sub as string
                // 
                // Save the Cognito ID to DynamoDB
                await saveToDynamoDB(cognitoId, email, name);

                // Navigate to admin page
                navigate("/admin", { replace: true });
            } catch (error) {
                console.error("Error fetching user details:", error);
            }
        };

        if (route === "authenticated") {
            updateUserWithCognitoId();
        }

        // fetchUserDetails();
    }, [navigate, route, user]);

    const saveToDynamoDB = async (cognitoId: string, email: string, name: string) => {
        const input: CreateCompanyOwnerInput = {
            id: cognitoId,
            email: email,
            name: name,
            subscriptionType: "free-demo",
            role: "super-admin",
            permissions: JSON.stringify(
                SUPER_ADMIN_USER_PERMISSIONS
            ),
            siteAdmin: false
        }

        try {
            const response = await client.graphql({
                query: createCompanyOwner,
                variables: { input },
            });
        } catch (error) {
            console.error('Error saving to DynamoDB:', error);
        }
    };

    const components = {
        SignUp: {
            Header() {
                return <SignUpHeader />
            },
        },
    };

    const formFields = {
        signUp: {
            name: {
                placeholder: "Enter Your Name Here",
                isRequired: true,
                label: "Name:",
            }
        },
    }

    return (
        <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            wrap="wrap"
            gap="1rem"
            width="100%"
            minHeight="100vh"
            position="relative"
            top="medium"
        >
            <View
                height="30rem"
                position="relative"
            >
                <Image
                    height="100%" width="100%" objectFit="cover"
                    src="/edited-loader.jpg"
                    alt="image of loader operations"
                    style={{
                        position: 'relative',
                        top: 0,
                        left: 0,
                        zIndex: 100
                    }}
                />
                <Image
                    height="150px"
                    objectFit="cover"
                    src="/Equipmentinspectionforms.com-transparent-logo.png"
                    alt="image of Quarry operations"
                    style={{
                        position: 'absolute',
                        top: 5,
                        left: 5,
                        zIndex: 200
                    }}
                />
                <AWSButton
                    backgroundColor="white"
                    loadingText=""
                    onClick={() => navigate("/", { replace: true })}
                    style={{
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        zIndex: 200
                    }}
                >
                    Back
                </AWSButton>
            </View>
            <View>
                <Authenticator
                    key="signUp"
                    initialState={"signUp"}
                    components={components}
                    formFields={formFields}
                    loginMechanisms={["email"]}
                ></Authenticator>
            </View>
        </Flex>
    )
};
