// import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppLayout, Button } from "@cloudscape-design/components";
import { UserProvider } from "./contexts/UserContext";
import { CompanyProvider } from "./contexts/CompanyContext";
import { AppRoutes } from "./AppRoutes";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer/Footer/Footer";

import "./App.css";

import { Theme, applyTheme } from '@cloudscape-design/components/theming';
// import { useEffect, useState } from "react";

const theme: Theme = {
  tokens: {
    // Values are applied globally, except for visual contexts
    colorBackgroundLayoutMain: {
      // Specify value for light and dark mode
      light: "dark-gray",
      dark: 'gray',
    },
    colorTextBodyDefault: {
      light: '#000000', // Set body text color for light mode
      dark: '#ffffff',  // Set body text color for dark mode
    },
    colorTextHeadingDefault: {
      light: '#00AA00', // Set heading text color for light mode
      dark: '#eeeeee',  // Set heading text color for dark mode
    },
    // Shorter syntax to apply the same value for both light and dark mode
    // colorTextAccent: '#0073bb',
  },
};
const { reset } = applyTheme({ theme });
// useEffect(() => {
//   // Apply the theme when the component mounts

//   // Optional: You can reset the theme when the component unmounts
//   // return () => {
//   //   reset();
//   // };
// }, []); // Empty dependency array ensures it runs once when the component mounts


// Use the reset method to remove the custom theme

export const App = () => {
  // const [isDarkMode, setIsDarkMode] = useState(false)

  // const toggleTheme = () => {
  //   setIsDarkMode(!isDarkMode)
  // }

  // useEffect(() => {
  //   document.body.setAttribute('data-awsui-theme', isDarkMode ? 'dark' : 'default')
  // }, [isDarkMode])
  return (
    <Router>
      <UserProvider>
        <CompanyProvider>
          <Navbar />
          <AppLayout
            ariaLabels={{
              navigation: "Navigation drawer",
              navigationClose: "Close navigation drawer",
              navigationToggle: "Open navigation drawer",
              notifications: "Notifications",
              tools: "Help panel",
              toolsClose: "Close help panel",
              toolsToggle: "Open help panel",
            }}
            content={
              <>
                {/* <Button onClick={toggleTheme}>
                  Switch to {isDarkMode ? "Light" : "Dark"} Mode
                </Button> */}
                <AppRoutes />
              </>
            }
            footerSelector="app-footer"
            headerSelector="awsui-context-top-navigation"
            maxContentWidth={Number.MAX_VALUE}
            navigationHide
            toolsHide
          />
          <Footer />
        </CompanyProvider>
      </UserProvider>
    </Router>
  );
};
