import {
  Box,
  Flashbar,
  Header,
  Link,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Card, Collection, Heading, View } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import * as Sentry from "@sentry/browser";
import { useUserContext } from "../contexts/UserContext";
import {
  PERMISSION_VALUES,
} from "../utils/constants";

import { DashboardContentProps } from "../types";
import { equipmentByCompanyId as equipmentByCompanyIdQuery, listCompanies } from "../graphql/queries";
import { Company, Equipment } from "../API";
import { CompanyInfoUser } from "./CompanyInfo";
import { formatDate, isDateAfter, userHasExplicitPermission, willExpireWithinAWeek } from "../utils/helperFunctions";
import "../css/DashboardContentUsers.css"
import { updateCompany, updateCompanyOwner } from "../graphql/mutations";
import { useCompanyContext } from "../contexts/CompanyContext";

const client = generateClient();

export const DashboardContent = ({ companyUser }: DashboardContentProps) => {
  const [pausedSubscription, setPausedSubscription] = useState<boolean>(false)
  const [willExpire, setWillExpire] = useState<boolean>(false)
  const [expirationDate, setExpirationDate] = useState<string>("")
  const [equipment, setEquipment] = useState<Equipment[]>([]);

  const { company } = useUserContext();
  const { setCompany } = useCompanyContext();


  const getEquipment = async () => {
    try {
      const equipmentResponse = await client.graphql({
        query: equipmentByCompanyIdQuery,
        variables: { companyId: company?.id.toString() as string },
      });
      setEquipment(equipmentResponse.data.equipmentByCompanyId.items as Equipment[]);
    } catch (error) {
      console.log("error", error);
      Sentry.captureException(error);
    }
  };

  const updateSubscription = async () => {
    const companyOwnerId = company?.companyOwnerId
    try {
      const companyOwnerInput = {
        id: companyOwnerId as string,
        subscriptionType: "paused"
      };
      const result = await client.graphql({ query: updateCompanyOwner, variables: { input: companyOwnerInput } })

      const companyResponse = await client.graphql({
        query: listCompanies,
      });
      const allCompanyData =
        companyResponse?.data?.listCompanies.items
      const companiesCurrentOwner: Company[] = allCompanyData.filter((owner) => owner.companyOwnerId === company?.companyOwnerId) as Company[]

      const updatePromises = companiesCurrentOwner.map(company => {
        const companyInput = {
          id: company.id, // Use the ID from each company
          subscriptionType: "paused", // New subscription type
        };

        return client.graphql({
          query: updateCompany,
          variables: { input: companyInput },
        });
      })

      const results = await Promise.all(updatePromises);
      setPausedSubscription(true)

      const current = results.filter((response) => response.data.updateCompany.id === company?.id)

      setCompany(current[0].data.updateCompany)


    } catch (error) {
      console.error("Error updating subscription:", error);
    } finally {
      getEquipment()
      // setIsEditSubscriptionTypeModalOpen(false)
    }
  }


  useEffect(() => {
    if (company !== null && company?.id) {
      setPausedSubscription(company.subscriptionType === "paused")

      const companyExpirationDate = company.subscriptionExpiration as string
      setExpirationDate(company.subscriptionExpiration as string)

      const today = new Date();
      const formattedToday = formatDate(today);

      const isExpired = isDateAfter(companyExpirationDate, formattedToday);
      const willExpireWithinWeek = willExpireWithinAWeek(companyExpirationDate, formattedToday,);

      if (isExpired && company.subscriptionType !== "paused") {
        updateSubscription()
      } else {
        setWillExpire(willExpireWithinWeek);
        getEquipment();
      }
    }
  }, [company]);

  return (
    <SpaceBetween direction="vertical" size="m">
      <Header variant="h1" id="page-top">Welcome {companyUser?.name}!</Header>
      {pausedSubscription ?
        <Flashbar items={[{
          type: "error",
          dismissible: false,
          content: (
            <>
              Please Contact Admin to Resume Subscription. {" "}
              {userHasExplicitPermission(companyUser, PERMISSION_VALUES.MANAGE_USERS) && <Link color="inverted" href="/manage/subscription">
                Click for Info
              </Link>}
            </>
          ),
          id: "expired message"
        }]}
        />
        : willExpire ?
          <Flashbar items={[{
            type: "warning",
            dismissible: false,
            content: (
              <>
                Warning Subscription due to Expire on <strong style={{ marginTop: '10px' }} > {expirationDate}</strong> {" "}
                {userHasExplicitPermission(companyUser, PERMISSION_VALUES.MANAGE_USERS) && <Link color="inverted" href="/manage/subscription">
                  Click for Info
                </Link>}
              </>
            ),
            id: "expired message"
          }]}
          />
          : <></>
      }
      <CompanyInfoUser />
      {userHasExplicitPermission(companyUser, PERMISSION_VALUES.INSPECT) && !pausedSubscription && <>
        <>
          <Box margin={{ vertical: "xs" }} textAlign="center"
            color="text-status-success">
            {equipment.length > 0 && (
              <>
                <h3>Scan a QR code to start an inspection</h3>
                <h2>OR</h2>
                <h4>Select a previously inspected item from list below and click to begin a new inspection</h4>
              </>
            )}
          </Box>

          <Collection
            items={equipment}
            type="list"
            direction="row"
            justifyContent="center"
            gap="1rem"
            wrap="wrap"
            width="100%"
          >
            {(item, index) => (
              <Card
                key={index}
                borderRadius="medium"
                variation="outlined"
                height="150px"
                width="150px"
                padding="0px"
              >
                <View textAlign="center">
                  <Link href={`/inspect/${item.id}`}>
                    <Heading padding="xs">{item.name}</Heading>
                  </Link>
                  {item.image && (
                    <Link href={`/inspect/${item.id}`}>
                      <img src={item.image} alt={item.name} className="dashboard-card-image" />
                    </Link>
                  )}
                </View>
              </Card>
            )}
          </Collection>
        </>
      </>}
    </SpaceBetween>
  );
};
