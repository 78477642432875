import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import generatePDF, { Options } from "react-to-pdf";
import { generateClient } from "aws-amplify/api";
import { getUrl } from "aws-amplify/storage";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  Icon,
  Link,
  Modal,
  SpaceBetween,
  StatusIndicator,
  Table,
} from "@cloudscape-design/components";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import * as Sentry from "@sentry/browser";
import { DisplayStatusIcon } from "./DisplayStatusIcon";
import { ValueWithLabel } from "./ValueWithLabel";
import { getReport as getReportQuery, discrepanciesByEquipmentIdAndUpdatedAt as discrepanciesByEquipmentIdQuery, discrepanciesByUnitIdAndUpdatedAt as discrepanciesByUnitIdQuery } from "../graphql/queries";
import { Report } from "../API";
import {
  DEFAULT_FORM_INPUT_TYPES,
  DEFAULT_REPORT_LABELS,
  ITEM_STATUS_CODES,
  LOCAL_STORAGE_KEYS,
} from "../utils/constants";
import { getTypeFromFileName, isNumeric } from "../utils/helperFunctions";
import { PDF_OPTIONS } from "../utils/constants";
import { tFile, tItems } from "../types";

const reportFromLS = localStorage.getItem(LOCAL_STORAGE_KEYS.REPORT) ? JSON.parse(
  localStorage.getItem(LOCAL_STORAGE_KEYS.REPORT) || '') : null;

const client = generateClient({ authMode: "userPool" });

type tReportData = Report & {
  lastServiceHours?: number;
}

type tIssueLog = {
  name: string;
  notes: string;
  date: Date;
}

type tIssuesReport = {
  status: string,
  initialStatus: string,
  questionIndex?: number | null,
  log: tIssueLog[]
}

export const InspectionReport = () => {
  const [renderPhotosModal, setRenderPhotosModal] = useState<tFile | null>(null);
  const [photosToPrint, setPhotosToPrint] = useState<string[]>([]);
  const { id: reportId = null, equipmentId } = useParams();
  const navigate = useNavigate();
  const [report, setReport] = useState<tReportData | undefined | null>(reportId && reportFromLS ? reportFromLS[reportId] : null);
  const [loc, setLoc] = useState(report ? JSON.parse(report?.userLocation) : {})
  const [issues, setIssues] = useState<tIssuesReport[]>([]);
  const [isLoadingIssues, setIsLoadingIssues] = useState<boolean>(false);

  // you can use a function to return the target element besides using React refs
  const getTargetElement = () => document.getElementById("content-id"); // Used for PDF generation

  useEffect(() => {
    const getIssues = async () => {
      if (!report) return;
      try {
        setIsLoadingIssues(true);
        const sortOrder = {
          [ITEM_STATUS_CODES.na]: 4,
          [ITEM_STATUS_CODES.ok]: 3,
          [ITEM_STATUS_CODES.warn]: 2,
          [ITEM_STATUS_CODES.inop]: 1
        }
        const hasUnitId = report.unitId;
        if (hasUnitId) {
          const res = await client.graphql({
            query: discrepanciesByUnitIdQuery,
            variables: { unitId: report.unitId as string }
          });
          const newIssues: tIssuesReport[] = []
          res.data.discrepanciesByUnitIdAndUpdatedAt.items.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1).forEach((item) => {
            const logInResponse = JSON.parse(item.log as string) as tIssueLog[]
            newIssues.push(
              {
                questionIndex: item.questionIndex,
                initialStatus: item.initialStatus || '',
                log: logInResponse,
                status: item.status,
              }
            )
          });
          setIssues(newIssues.sort((a, b) => {
            return sortOrder[a.status] - sortOrder[b.status];
          }));
        } else {
          const res = await client.graphql({
            query: discrepanciesByEquipmentIdQuery,
            variables: { equipmentId: report.equipmentId as string }
          })
          const newIssues: tIssuesReport[] = []
          res.data.discrepanciesByEquipmentIdAndUpdatedAt.items.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1).forEach((item) => {
            const logInResponse = JSON.parse(item.log as string) as tIssueLog[]
            newIssues.push(
              {
                questionIndex: item.questionIndex,
                initialStatus: item.initialStatus || '',
                log: logInResponse,
                status: item.status,
              }
            )
          });
          setIssues(newIssues.sort((a, b) => {
            return sortOrder[a.status] - sortOrder[b.status];
          }));
        }
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      } finally {
        setIsLoadingIssues(false);
      }
    }

    if (report) {
      const items: tItems[] = report?.userData ? JSON.parse(report.userData) : [];
      const hasIssue = items?.some((item: { value: string; }) => item.value === ITEM_STATUS_CODES.inop || item.value === ITEM_STATUS_CODES.warn);
      if (hasIssue) {
        getIssues();
      }
    }
  }, [report]);

  useEffect(() => {
    const getReport = async () => {
      if (!reportId) {
        return;
      }
      try {
        const res = await client.graphql({
          query: getReportQuery,
          variables: {
            id: reportId,
          },
        });
        setReport(res.data.getReport);
        if (res?.data?.getReport?.userLocation) {
          setLoc(JSON.parse(res.data.getReport.userLocation))
        }
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      }
    };
    if (!report && reportId) {
      getReport();
    }
  }, [report, loc, reportId])

  useEffect(() => {
    const getListOfPhotosToRenderForPrint = async () => {
      if (!report?.userData) return;
      const newPhotos = [];
      const userdata = JSON.parse(report.userData);
      try {
        for (const el of userdata) {
          if (el.photos) {
            for (const photo of el.photos) {
              const photoUrl = await getUrl({
                path: `public/${photo}`,
              });
              const path = photoUrl.url.pathname;
              const url = photoUrl.url.href;
              if (
                path.includes(".png") ||
                path.includes(".jpeg") ||
                path.includes(".jpg")
              ) {
                newPhotos.push(url);
              }
            }
          }
        }
        setPhotosToPrint([...newPhotos]);
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      }
    };
    getListOfPhotosToRenderForPrint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFileTypeFromFilename = (fileName: string) => {
    const type = getTypeFromFileName(fileName);
    switch (type) {
      case "image":
        return <i className="fa-solid fa-image" />;
      case "video":
        return <i className="fa-solid fa-video" />;
      case "audio":
        return <i className="fa-solid fa-microphone-lines" />;
      default:
        return fileName;
    }
  };

  const renderMediaFile = (fileName: tFile | null) => {
    const mediaFileUrl = fileName?.url?.href;
    if (!mediaFileUrl) return null;
    const type = getTypeFromFileName(mediaFileUrl);
    switch (type) {
      case "image":
      default:
        return <img src={mediaFileUrl} alt="detail" width="100%" />;
      case "video":
        return <video src={mediaFileUrl} controls width="100%" />;
      case "audio":
        return <audio src={mediaFileUrl} controls />;
    }
  };

  const items: tItems[] = report?.userData ? JSON.parse(report.userData) : [];
  const machineHours = items.find(
    (item: { label: string; }) => item.label === DEFAULT_REPORT_LABELS.machineHours
  )?.value;

  const mostSevereStatus = () => {
    const hasInOp = items.some((item: { value: string; }) => item.value === ITEM_STATUS_CODES.inop);
    const hasWarn = items.some((item: { value: string; }) => item.value === ITEM_STATUS_CODES.warn);
    switch (true) {
      case hasInOp:
        return <StatusIndicator type="error">Out Of Service</StatusIndicator>;
      case hasWarn:
        return (<>
          <Icon url="/status-warning-svgrepo-com.svg" size="medium" variant="normal" /> <span style={{
            color: "#ebe424", fontWeight: "bold", fontSize: "medium"
          }}>Warning</span></>)
      default:
        return <StatusIndicator type="success">OK</StatusIndicator>;
    }
  };

  const mostSevereCurrentStatus = () => {
    switch (true) {
      case issues[0].status === ITEM_STATUS_CODES.inop:
        return <StatusIndicator type="error">Out Of Service</StatusIndicator>;
      case issues[0].status === ITEM_STATUS_CODES.warn:
        return (<>
          <Icon url="/status-warning-svgrepo-com.svg" size="medium" variant="normal" /> <span style={{ color: "#ebe424", fontWeight: "bold" }}>Warning</span></>)
      default:
        return <StatusIndicator type="success">OK</StatusIndicator>;
    }
  };

  return (
    <div id="content-id">
      <SpaceBetween direction="vertical" size="m">
        <>
          <Header
            variant="h2"
            actions={
              <div className="hide-from-printer">
                <SpaceBetween direction="horizontal" size="l">
                  <Button
                    variant="inline-link"
                    onClick={() => {
                      window.print();
                    }}
                  >
                    <PrintIcon />
                  </Button>
                  <Button
                    variant="inline-link"
                    onClick={() =>
                      generatePDF(getTargetElement, {
                        filename: `${report?.equipmentName} ${report?.unitName ? `/ ${report?.unitName}` : ""
                          }`,
                        ...PDF_OPTIONS,
                      } as Options)
                    }
                  >
                    <DownloadIcon />
                  </Button>
                  <Link onFollow={() => navigate(-1)}>Back</Link>
                </SpaceBetween>
              </div>
            }
          >{`${report?.equipmentName} ${report?.unitName ? `/ ${report?.unitName}` : ""
            }`}</Header>

          {equipmentId && (
            <Button
              variant="inline-link"
              onClick={() => {
                window.close();
              }}
            >
              Close
            </Button>
          )}
          <div className="print-header-div">
            <Container>
              <ColumnLayout columns={3} variant="text-grid">
                <SpaceBetween size="m">
                  <ValueWithLabel label="Date / Time">
                    {new Date(report?.createdAt || Date.now()).toLocaleString()}
                  </ValueWithLabel>
                  <ValueWithLabel label="Submitted By">
                    {report?.name}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="m">
                  <ValueWithLabel label={DEFAULT_REPORT_LABELS.lastServiceHours}>
                    {report?.lastServiceHours?.toLocaleString()}
                  </ValueWithLabel>
                  <ValueWithLabel
                    label={DEFAULT_REPORT_LABELS.timeSinceLastService}
                  >
                    {isNumeric(machineHours) && isNumeric(report?.lastServiceHours)
                      ? (
                        Number(machineHours) - Number(report?.lastServiceHours)
                      ).toLocaleString()
                      : "--"}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="m">
                  <ValueWithLabel label={issues.length > 0 && issues[0].status !== issues[0].initialStatus ? 'Status Submitted / Current Status' : 'Status'}>
                    {issues.length > 0 && issues[0].status !== issues[0].initialStatus ? <>{mostSevereStatus()} / {mostSevereCurrentStatus()}</> : mostSevereStatus()}
                  </ValueWithLabel>
                  <ValueWithLabel label="Location Source">
                    {loc?.type || "--"}
                  </ValueWithLabel>
                </SpaceBetween>
              </ColumnLayout>
            </Container>
          </div>
          {isLoadingIssues || issues.length > 0 &&
            <>
              <div className="viewInMobile">
                <Table header={<Header variant="h2">Issues Log</Header>} wrapLines items={issues} columnDefinitions={[
                  {
                    id: "section",
                    header: "Section",
                    cell: (item) => {
                      const prompts: tItems[] = report?.userData ? JSON.parse(report.userData) : [];
                      const section = item.questionIndex ? prompts[item.questionIndex] : null;
                      // return section ? `${section.title} - ${section.label}` : ''
                      return (
                        <>
                          <Box variant="span" fontWeight="bold">
                            {section ? section.label : ''}
                          </Box>
                          {section?.title ? <br /> : ""}
                          <Box variant="small" fontWeight="heavy">
                            {section ? section.title : ''}
                          </Box>
                          {section?.label ? <br /> : ""}
                          <Box variant="small">
                            {section ? section.description : ""}
                          </Box>
                        </>
                      )
                    }
                  },
                  {
                    id: "status",
                    header: "Current Status",
                    cell: (item) => <DisplayStatusIcon size="small">{item.status}</DisplayStatusIcon>,
                  },
                  {
                    id: "initialStatus",
                    header: "Initial Status",
                    cell: (item) => <DisplayStatusIcon size="small">{item.initialStatus}</DisplayStatusIcon>,
                  },
                  {
                    id: "log",
                    header: "Log",
                    cell: (item) => item.log.map((log: tIssueLog, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <Box variant="p" margin={{ top: "m" }}>{log.notes}</Box>
                          <Box variant="small" fontWeight="bold">{log.name}</Box>
                          <Box variant="small">{new Date(log.date).toLocaleString()}</Box>
                        </React.Fragment>
                      );
                    })
                  },
                ]} loading={isLoadingIssues} />
              </div>
              <div className="viewInDesktop">
                <Table
                  header={<Header variant="h2">Issues Log</Header>}
                  wrapLines items={issues} columnDefinitions={[
                    {
                      id: "section",
                      header: "Section",
                      cell: (item) => {
                        const prompts: tItems[] = report?.userData ? JSON.parse(report.userData) : [];
                        const section = item.questionIndex ? prompts[item.questionIndex] : null;
                        return section ? `${section.title} - ${section.label}` : ''
                      }
                    },
                    {
                      id: "status",
                      header: "Current Status",
                      cell: (item) => <DisplayStatusIcon size="medium">{item.status}</DisplayStatusIcon>,
                    },
                    {
                      id: "initialStatus",
                      header: "Initial Status",
                      cell: (item) => <DisplayStatusIcon size="medium">{item.initialStatus}</DisplayStatusIcon>,
                    },
                    {
                      id: "log",
                      header: "Log",
                      cell: (item) => item.log.map((log: tIssueLog, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <Box variant="p" margin={{ top: "l" }}>{log.notes}</Box>
                            <Box variant="span" fontWeight="bold">{log.name}</Box>{' - '}
                            <Box variant="span">{new Date(log.date).toLocaleString()}</Box>
                          </React.Fragment>
                        );
                      })
                    },
                  ]} loading={isLoadingIssues} />
              </div>
            </>
          }
          {loc?.latitude && loc?.longitude && (
            <>
              <div className="hide-from-printer">
                <Header variant="h2">Map</Header>

              </div>
              <div className="hide-from-printer">
                <div style={{ maxWidth: "100%", margin: "0 auto" }}>
                  <MapContainer
                    id="map"
                    center={[loc.latitude, loc.longitude]}
                    zoom={13}
                    scrollWheelZoom={false}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[loc.latitude, loc.longitude]}>
                      <Popup>
                        <Box>{`${report?.equipmentName} ${report?.unitName ? `/ ${report?.unitName}` : ""
                          }`}</Box>
                        <Box>{report?.name}</Box>
                      </Popup>
                    </Marker>
                  </MapContainer>
                </div>
              </div>
            </>
          )}
        </>
        <div className="viewInMobile">
          <Table
            header={<Header variant="h2">Inspection Details</Header>}
            // variant="embedded"
            columnDefinitions={[
              {
                id: "prompt",
                header: "Component",
                cell: (item) => (
                  <>
                    <Box variant="span" fontWeight="bold">
                      {item.label}
                    </Box>
                    {item.title ? <br /> : ""}
                    <Box variant="small" fontWeight="heavy">
                      {item.type === DEFAULT_FORM_INPUT_TYPES.section
                        ? item.title
                        : ""}
                    </Box>
                    {item.description ? <br /> : ""}
                    <Box variant="small">
                      {item.description}
                    </Box>
                  </>

                ),
              },
              // {
              //   id: "section",
              //   header: "Section",
              //   cell: (item) =>
              //     item.type === DEFAULT_FORM_INPUT_TYPES.section
              //       ? item.title
              //       : "",
              // },
              // {
              //   id: "description",
              //   header: "Description",
              //   cell: (item) => ,
              // },
              {
                id: "value",
                header: "Value",
                cell: (item) => (
                  <DisplayStatusIcon size="medium">
                    {isNumeric(item.value)
                      ? Number(item.value).toLocaleString()
                      : item.value}
                  </DisplayStatusIcon>
                ),
              },
              {
                id: "notes",
                header: "Notes",
                cell: (item) => item.notes,
              },
              {
                id: "media",
                header: "Media",
                cell: (item) =>
                  item.photos?.length ? (
                    <div className="hide-from-printer">
                      <SpaceBetween direction="horizontal" size="s">
                        {item.photos.map((media) => {
                          return (
                            <Button
                              variant="inline-link"
                              key={media}
                              onClick={async () => {
                                const photoUrl = (await getUrl({
                                  path: `public/${media}`,
                                })) as tFile;
                                setRenderPhotosModal(photoUrl);
                              }}
                            >
                              {getFileTypeFromFilename(media)}
                            </Button>
                          );
                        })}
                      </SpaceBetween>
                    </div>
                  ) : (
                    "--"
                  ),
              },
            ]}
            items={items}
            wrapLines
          />
        </div>
        <div className="viewInDesktop">
          <Table
            header={<Header variant="h2">Inspection Details</Header>}
            // variant="embedded"
            columnDefinitions={[
              {
                id: "prompt",
                header: "Prompt",
                cell: (item) => item.label,
              },
              {
                id: "section",
                header: "Section",
                cell: (item) =>
                  item.type === DEFAULT_FORM_INPUT_TYPES.section
                    ? item.title
                    : "",
              },
              {
                id: "description",
                header: "Description",
                cell: (item) => item.description,
              },
              {
                id: "value",
                header: "Value",
                cell: (item) => (
                  <DisplayStatusIcon size="big">
                    {isNumeric(item.value)
                      ? Number(item.value).toLocaleString()
                      : item.value}
                  </DisplayStatusIcon>
                ),
              },
              {
                id: "notes",
                header: "Notes",
                cell: (item) => item.notes,
              },
              {
                id: "media",
                header: "Media",
                cell: (item) =>
                  item.photos?.length ? (
                    <div className="hide-from-printer">
                      <SpaceBetween direction="horizontal" size="s">
                        {item.photos.map((media) => {
                          return (
                            <Button
                              variant="inline-link"
                              key={media}
                              onClick={async () => {
                                const photoUrl = (await getUrl({
                                  path: `public/${media}`,
                                })) as tFile;
                                setRenderPhotosModal(photoUrl);
                              }}
                            >
                              {getFileTypeFromFilename(media)}
                            </Button>
                          );
                        })}
                      </SpaceBetween>
                    </div>
                  ) : (
                    "--"
                  ),
              },
            ]}
            items={items}
            wrapLines
          />
        </div>
        <div className="viewInPrint">
          <Table
            header={<Header variant="h2">Inspection Details</Header>}
            variant="embedded"
            columnDefinitions={[
              {
                id: "prompt",
                header: "Component",
                cell: (item) => (
                  <>
                    <Box variant="span" fontWeight="bold">
                      {item.label} {item.title ? ` -- ${item.title}` : ""}
                    </Box>
                    {item.description ? <br /> : ""}
                    <Box variant="small">
                      {item.description}
                    </Box>
                  </>

                ),
              },
              {
                id: "value",
                header: "Value",
                cell: (item) => (
                  <DisplayStatusIcon size="small">
                    {isNumeric(item.value)
                      ? Number(item.value).toLocaleString()
                      : item.value}
                  </DisplayStatusIcon>
                ),
              },
              {
                id: "notes",
                header: "Notes",
                cell: (item) => item.notes,
              },
              {
                id: "media",
                header: "Media",
                cell: (item) =>
                  item.photos?.length ? (
                    <div className="hide-from-printer">
                      <SpaceBetween direction="horizontal" size="s">
                        {item.photos.map((media) => {
                          return (
                            <Button
                              variant="inline-link"
                              key={media}
                              onClick={async () => {
                                const photoUrl = (await getUrl({
                                  path: `public/${media}`,
                                })) as tFile;
                                setRenderPhotosModal(photoUrl);
                              }}
                            >
                              {getFileTypeFromFilename(media)}
                            </Button>
                          );
                        })}
                      </SpaceBetween>
                    </div>
                  ) : (
                    "--"
                  ),
              },
            ]}
            items={items}
            wrapLines
          />
        </div>
      </SpaceBetween>
      <Modal
        onDismiss={() => setRenderPhotosModal(null)}
        visible={!!renderPhotosModal}
        header="Inspection Media"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="primary"
                onClick={() => setRenderPhotosModal(null)}
              >
                Close
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        {renderMediaFile(renderPhotosModal)}
      </Modal>
      <div className="printer-only-media">
        {photosToPrint.map((photoUrl, idx) => {
          return (
            <span key={idx} className="upload-media">
              <img src={photoUrl} alt="detail" width="100%" />
            </span>
          );
        })}
      </div>
    </div>
  );
};
