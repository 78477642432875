import { useEffect, useState } from "react";
import { useIndexedDB } from "react-indexed-db-hook";
import { v4 as uuidv4 } from "uuid";
import { usePageVisibility } from "react-page-visibility";
import { Box, Container, SpaceBetween } from "@cloudscape-design/components";
import { generateClient } from "aws-amplify/api";
import { uploadData } from "aws-amplify/storage";
import * as Sentry from "@sentry/browser";
import { useUserContext } from "../contexts/UserContext";
import {
  createDiscrepancy as createDiscrepancyMutation,
  createReport as createReportMutation,
  createErrors as createErrorsMutation,
  updateEquipment as updateEquipmentMutation,
} from "../graphql/mutations";
import { getEquipment as getEquipmentQuery } from "../graphql/queries";
import { useNetworkState } from "@uidotdev/usehooks";
import { ITEM_STATUS_CODES, LOCAL_STORAGE_KEYS, REPORT_SENDING_STATE } from "../utils/constants";
import { Equipment, Report } from "../API";
import { tInspectionFormInput, tMediaInput, tSendReport, tStoredReport } from "../types";
import { useAuthenticator } from "@aws-amplify/ui-react";

const client = generateClient({ authMode: "userPool" });

export const ReportSent = () => {
  const network = useNetworkState();
  const [reportStatus, setReportStatus] = useState(
    REPORT_SENDING_STATE.CHECKING_NETWORK
  );
  const [mediaFilesUploadedCount, setMediaFilesUploadedCount] = useState(0);
  const [queryResponse, setQueryResponse] = useState<Report | null>(null);
  const [reportToSend, setReporttoSend] = useState(
    localStorage.getItem(LOCAL_STORAGE_KEYS.REPORT_TO_SEND)
  );
  const { companyUser } = useUserContext();
  const isTabVisible = usePageVisibility();
  const equipmentName = localStorage.getItem(LOCAL_STORAGE_KEYS.EQUIPMENT_NAME);
  const { deleteRecord, getByID } = useIndexedDB("media");
  const { user } = useAuthenticator((context) => [
    context.user,
  ]);

  const storedCompanyId = localStorage.getItem("storedCompanyId")

  const sendReport = async (input: tSendReport) => {
    if (!companyUser && !storedCompanyId) {
      return;
    }
    // go through each image and upload to s3
    const mediaObj: { [key: number]: tMediaInput[] } | null = JSON.parse(input.media || '');
    const promptArray: tInspectionFormInput[] = JSON.parse(input.userData);
    const imagesToDelete = [];
    if (mediaObj) {
      for (const key in mediaObj) {
        const imgArray = mediaObj[key];
        promptArray[key].photos = [];
        for (const [, img] of imgArray.entries()) {
          const imageFile = await getByID(img.id);
          const fileName = `${uuidv4()}-${img.name}`;
          try {
            const result = await uploadData({
              path: `public/${fileName}`,
              data: imageFile.file,
            }).result;
            if (result?.path) {
              setMediaFilesUploadedCount((prev) => prev + 1);
            }
            promptArray[key].photos.push(fileName);
            imagesToDelete.push(img.id);
          } catch (error) {
            console.log(error);
            Sentry.captureException(error);
            await client.graphql({
              query: createErrorsMutation,
              variables: { input: { errors: JSON.stringify(error, null, 2) } },
            });
          }
        }
      }
    }
    delete input.media;
    const newInput = {
      ...input,
      userId: companyUser?.id || user.userId,
      name: companyUser?.name || user.username,
      userData: JSON.stringify(promptArray),
    };
    try {
      const response = await client.graphql({
        query: createReportMutation,
        variables: { input: newInput },
      });
      promptArray.forEach((prompt, index) => { //If promptArray has an inop or warn, create an issue.
        if (prompt.value === ITEM_STATUS_CODES.warn || prompt.value === ITEM_STATUS_CODES.inop) {
          client.graphql({
            query: createDiscrepancyMutation,
            variables: {
              input: {
                companyId: input.companyId,
                equipmentId: input.equipmentId,
                equipmentName: input.equipmentName,
                questionIndex: index,
                unitId: input.unitId,
                status: prompt.value,
                initialStatus: prompt.value,
                log: JSON.stringify([{ name: companyUser?.name || user.username, notes: prompt.notes, media: prompt.photos, date: new Date().toISOString() }])
              },
            },
          });
        }
      });
      setQueryResponse(response.data.createReport);
      setReportStatus(REPORT_SENDING_STATE.REPORT_SENT);
      for (const id of imagesToDelete) {
        await deleteRecord(id);
      }
      if (input.equipmentId && !input.unitId) {
        // if the equipment does not have separate units
        const equipUpdateData = {
          id: input.equipmentId,
          machineHours: Number(promptArray[0]?.value),
        };
        await client.graphql({
          query: updateEquipmentMutation,
          variables: { input: equipUpdateData },
        });
      } else {
        const equipToUpdate = await client.graphql({
          query: getEquipmentQuery,
          variables: { id: input.equipmentId },
        });
        const equipmentList = [...equipToUpdate.data.getEquipment?.equipment as []] as Equipment[];
        const equipToUpdateIndex = equipmentList.findIndex(
          (item) => item.id === input.unitId
        );
        equipmentList[equipToUpdateIndex].machineHours = parseFloat(
          promptArray[0].value
        );
        await client.graphql({
          query: updateEquipmentMutation,
          variables: {
            input: {
              id: input.equipmentId,
              equipment: equipmentList.map((equip) => {
                return {
                  id: equip.id,
                  name: equip.name,
                  machineHours: equip.machineHours,
                  lastServiceHours: equip.lastServiceHours,
                };
              }),
            },
          },
        });
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      setReportStatus(
        `${REPORT_SENDING_STATE.REPORT_ERROR} ${JSON.stringify(error)}`
      );
      await client.graphql({
        query: createErrorsMutation,
        variables: { input: { errors: JSON.stringify(error, null, 2) } },
      });
    }
  };

  useEffect(() => {
    if (
      companyUser?.name || storedCompanyId && // If user is logged in
      isTabVisible && // If the tab is visibile (active)
      network.online && // If the user is online
      localStorage.getItem(LOCAL_STORAGE_KEYS.REPORT_TO_SEND) && // If a report is waiting to be sent
      reportStatus !== REPORT_SENDING_STATE.REPORT_SENDING // If the report is not already being sent
    ) {
      setReportStatus(REPORT_SENDING_STATE.REPORT_SENDING);
      const input: tStoredReport = JSON.parse(reportToSend || '');
      localStorage.removeItem(LOCAL_STORAGE_KEYS.REPORT_TO_SEND);
      setReporttoSend(null);
      sendReport(input);
    } else if (!network.online) {
      setReportStatus(REPORT_SENDING_STATE.OFFLINE);
    } else {
      setReportStatus(REPORT_SENDING_STATE.REPORT_SENT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network.online, isTabVisible, companyUser]);

  return (
    <SpaceBetween direction="vertical" size="l">
      <Container>
        <Box variant="h1">{reportStatus}</Box>
        <Box variant="h2">{equipmentName}</Box>
        {reportStatus === REPORT_SENDING_STATE.REPORT_SENT &&
          queryResponse?.createdAt && (
            <>
              <Box variant="p">
                Your report has been sent at{" "}
                {new Date(queryResponse.createdAt).toLocaleDateString()}{" "}
                {new Date(queryResponse.createdAt).toLocaleTimeString()}
              </Box>
              <Box variant="p">
                Media files uploaded: {mediaFilesUploadedCount}
              </Box>
              <Box variant="p">You can close this browser or tab.</Box>
            </>
          )}
        {reportStatus === REPORT_SENDING_STATE.OFFLINE && (
          <>
            <Box variant="p">Keep this browser tab open.</Box>
          </>
        )}
      </Container>
    </SpaceBetween>
  );
};
