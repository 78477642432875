import { useNavigate } from "react-router-dom";

import {
  Box,
  BreadcrumbGroup,
  Cards,
  Header,
  Link,
  SpaceBetween,
} from "@cloudscape-design/components";

import { useCompanyContext } from "../contexts/CompanyContext";
import {
  DASHBOARD_CARD_ELEMENTS,
} from "../utils/constants";

import { iCompanyProps } from "../types";
import { useState } from "react";

export const Company = ({
  hideBreadCrumbs = false,
  parent,
  selfPath = '',
}: iCompanyProps): JSX.Element => {

  const storedCompanyName = localStorage.getItem("storedCompanyName")

  const navigate = useNavigate();

  return (
    <>
      <SpaceBetween direction="vertical" size="l">
        {!hideBreadCrumbs && storedCompanyName && (
          <div className="hide-from-printer">
            <BreadcrumbGroup
              onFollow={(event) => {
                event.preventDefault();
                navigate(event.detail.href);
              }}
              items={[
                { text: "Company Administration", href: parent as string },
                {
                  text: storedCompanyName || '',
                  href: selfPath,
                },
              ]}
              ariaLabel="Breadcrumbs"
            />
          </div>
        )}
        <Header variant="h2">Links for Site Navigation</Header>
        <Cards
          cardDefinition={{
            header: (item) => (
              <Link
                onFollow={(e) => {
                  e.preventDefault();
                  navigate(item.href);
                }}
              >
                <Header variant="h2">{item.text}</Header>
              </Link>
            ),
            sections: [
              {
                id: "description",
                header: (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center"
                    }}
                  >
                  </div>
                ),
                content: item => <h3>{item.description}</h3>
              },
              {
                id: "icon",
                content: (item) => (
                  <Link
                    onFollow={(e) => {
                      e.preventDefault();
                      navigate(item.href);
                    }}
                  >
                    <Box textAlign="center">
                      <img src={`./${item.icon}`} alt={item.text} width="50%" />
                    </Box>
                  </Link>
                ),
              },

            ],
          }}
          cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }, { minWidth: 800, cards: 3 }, { minWidth: 1100, cards: 5 }, { minWidth: 1700, cards: 6 }]}
          items={
            DASHBOARD_CARD_ELEMENTS
          }
        />
      </SpaceBetween>
    </>
  )
}
