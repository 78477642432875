import Resizer from "react-image-file-resizer";
import { SUPER_ADMIN_USER_IDS, FREE_DEMO_USER_IDS, PERMISSION_VALUES } from "./constants";
import { User } from "../API";


export const getBaseRoute = (path: string): string => path.split("/")[1];

export const capitalizeWord = (word: string): string =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const isNumeric = (n: string | number | undefined): boolean => {
  if (typeof n === "undefined") return false;
  return !isNaN(parseFloat(`${n}`)) && isFinite(Number(n));
};

export const resizeFile = (file: Blob) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const resizeLogo = (file: Blob) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const base64ToBlob = (base64: string | null, mimeType: string): Blob | null => {
  if (!base64 || base64.split(",")[0] !== "data:image/jpeg;base64") {
    return null; // Return null if base64 is invalid
  }
  const byteString = atob(base64.split(',')[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([intArray], { type: mimeType });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNumberOfPrompts = (formDataArray: any[]) => {
  let numberOfPrompts = 0;
  if (!formDataArray?.length) return 0;
  formDataArray.forEach((formData) => {
    if (formData.prompt) {
      numberOfPrompts++;
    }
    if (formData.prompts) {
      numberOfPrompts += formData.prompts.length;
    }
  });
  return numberOfPrompts;
};

export const getTypeFromFileName = (fileName: string): string | null => {
  if (!fileName) return null;
  const path = fileName.toLowerCase();
  switch (true) {
    case path.includes(".png") ||
      path.includes(".jpeg") ||
      path.includes(".jpg"):
    default:
      return "image";
    case path.includes(".mov") || path.includes(".mp4"):
      return "video";
    case path.includes(".mp3") || path.includes(".wav"):
      return "audio";
  }
};

export const userHasExplicitPermission = (user: User | null, permission: PERMISSION_VALUES | undefined): boolean => {
  if (!permission) {
    return false;
  }
  return !!user?.permissions?.includes(permission);
}

export function isDateAfter(date1: string, date2: string) {
  // Parse dates in MM/DD/YYYY format
  const [month1, day1, year1] = date1.split(",")[0].split('/').map(Number);
  const [month2, day2, year2] = date2.split('/').map(Number);

  const parsedDate1 = new Date(year1, month1 - 1, day1); // Months are 0-indexed
  const parsedDate2 = new Date(year2, month2 - 1, day2);

  return parsedDate2 >= parsedDate1;
}

export function willExpireWithinAWeek(dateToCheck: string, today: string): boolean {
  // Parse dates in MM/DD/YYYY format
  const [month1, day1, year1] = dateToCheck.split('/').map(Number);
  const [month2, day2, year2] = today.split('/').map(Number);

  const parsedDateToCheck = new Date(year1, month1 - 1, day1); // Months are 0-indexed
  const parsedToday = new Date(year2, month2 - 1, day2);

  // Calculate the time difference in milliseconds
  const diffInMs = parsedDateToCheck.getTime() - parsedToday.getTime();

  // Convert the difference to days
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  // Check if the date is within a week but still in the future
  return diffInDays > 0 && diffInDays <= 7;
}

export function formatDate(date: Date) {
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getUTCDate()).padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${month}/${day}/${year}`;
}



export const getEarliestDate = (array: { subscriptionExpiration: string }[]): string => {
  return array.reduce((earliest, current) => {
    const earliestDate = new Date(earliest);
    const currentDate = new Date(current.subscriptionExpiration);

    return currentDate < earliestDate ? current.subscriptionExpiration : earliest;
  }, array[0].subscriptionExpiration); // Initialize with the first item's date
};

export const getEarliestDateAndCompany = (array: { name: string; subscriptionExpiration: string }[]) => {
  return array.reduce((earliest, current) => {
    const earliestDate = new Date(earliest.subscriptionExpiration);
    const currentDate = new Date(current.subscriptionExpiration);

    return currentDate < earliestDate ? current : earliest;
  }, array[0]); // Initialize with the first item
};

export const isDateInFuture = (dateString: string | undefined) => {
  if (!dateString) return false; // Handle cases where the date is undefined or null.
  const [month, day, year] = dateString.split('/').map(Number); // Assuming MM/DD/YYYY format
  const expirationDate = new Date(year, month - 1, day); // Months are 0-indexed
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to midnight for accurate comparison
  return expirationDate > today;
};
