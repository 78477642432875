
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIndexedDB } from "react-indexed-db-hook";
import { useReactMediaRecorder } from "react-media-recorder";
import Dropzone, { IFileWithMeta, IStyleCustomization, StatusValue } from "react-dropzone-uploader";
import { generateClient } from "aws-amplify/api";
import { Button as MUIButton } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { yellow } from "@mui/material/colors";
import {
  Badge,
  Box,
  Button,
  Container,
  Form,
  FormField,
  Grid,
  Header,
  Icon,
  Input,
  Link,
  ProgressBar,
  SpaceBetween,
  Textarea,
} from "@cloudscape-design/components";
import { Flex, InputMode } from "@aws-amplify/ui-react";
import { useNetworkState } from "@uidotdev/usehooks";
import * as Sentry from "@sentry/browser";
import { LoadingSpinner } from "./LoadingSpinner";
import { ReviewReport } from "./ReviewReport";
import { getForm as getFormQuery } from "../graphql/queries";

import { useUserContext } from "../contexts/UserContext";
import {
  DEFAULT_REPORT_LABELS,
  DEFAULT_FORM_INPUT_TYPES,
  INPUT_MODES,
  ITEM_STATUS_CODES,
  LOCAL_STORAGE_KEYS,
  LOCATION_TYPES,
} from "../utils/constants";

import { iInspectProps, tFormPrompts, tGPSInput, tGPSPosition, tInspectionFormInput, tMediaAction, tMediaInput } from "../types";

import "react-dropzone-uploader/dist/styles.css";
import { Key } from "react-indexed-db-hook/lib/indexed-db";

const client = generateClient({ authMode: "userPool" });

const theme = createTheme({
  palette: {
    secondary: yellow,
  },
});

export const Inspect = ({
  backBtnAction,
  equipmentData,
  equipmentId,
  selectedUnitId,
}: iInspectProps) => {
  const network = useNetworkState();
  const { company } = useUserContext();
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0); // --> Current step of the wizard
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLocating, setIsLocating] = useState(false);
  const [userLocation, setUserLocation] = useState<tGPSInput | null>(null);
  const [inspectionFormPrompts, setInspectionFormPrompts] = useState<tInspectionFormInput[]>([]);
  const [media, setMedia] = useState<{ [key: number]: tMediaInput[] } | null>(null);
  const [mediaAction, setMediaAction] = useState<tMediaAction | null>(null);
  const [shouldReviewForm, setShouldReviewForm] = useState(false);
  const [audioReview, setAudioReview] = useState<{ [key: number]: Blob }>({});
  const navigate = useNavigate();
  const { add, getByID, deleteRecord } = useIndexedDB("media");
  const { clearBlobUrl, status, startRecording, stopRecording } =
    useReactMediaRecorder({
      mediaRecorderOptions: {
        mimeType: "audio/wav",
      },
      audio: true,
      onStop: async (_url, blob) => {
        const obj = {
          name: "audio.wav",
          file: blob,
          question: activeStepIndex,
          formId: equipmentData.formId,
        };
        const event = await add(obj);
        setAudioReview({
          ...audioReview,
          [activeStepIndex]: blob,
        });
        setMedia((prev) => {
          return {
            ...prev,
            [activeStepIndex]: (prev && prev[activeStepIndex])
              ? [
                ...prev[activeStepIndex],
                {
                  id: event,
                  name: "audio.wav",
                },
              ]
              : [
                {
                  id: event,
                  name: "audio.wav",
                },
              ],
          };
        });
      },
    });

  const coordinates =
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOCATION) || '{}') || {};

  const storedCompanyId = localStorage.getItem("storedCompanyId")
  const companyId = company?.id as string || JSON.parse(storedCompanyId as string)


  const createInspectionReport = () => {
    if (!company && !storedCompanyId) {
      return;
    }
    setIsLoading(true);
    const input = {
      companyId: companyId,
      equipmentId: equipmentId,
      equipmentName: selectedUnitId?.label ? `${equipmentData.name} - ${selectedUnitId.label}` : equipmentData.name,
      unitId: selectedUnitId?.value,
      unitName: selectedUnitId?.label,
      userData: JSON.stringify(inspectionFormPrompts),
      userLocation: JSON.stringify(userLocation),
      media: JSON.stringify(media),
    };
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.REPORT_TO_SEND,
      JSON.stringify(input)
    );
    localStorage.setItem(LOCAL_STORAGE_KEYS.EQUIPMENT_NAME, equipmentData.name);
    setIsLoading(false);
    window.history.pushState(null, '', "/");
    navigate(storedCompanyId ? "/company/report-sent" : "/report-sent", { replace: true });
  };

  useEffect(() => {
    setLocationFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.INSPECT, equipmentId);
  }, [equipmentId]);

  useEffect(() => {
    const getForm = async () => {
      try {
        const formResponse =
          network.online && companyId
            ? await client.graphql({
              query: getFormQuery,
              variables: { id: equipmentData.formId },
            })
            : JSON.parse(localStorage.getItem(`form_${equipmentData.formId}`) || '{}');
        const prompts: tInspectionFormInput[] = formResponse?.data?.getForm?.noMachineHours
          ? []
          : [
            {
              type: DEFAULT_FORM_INPUT_TYPES.question,
              label: DEFAULT_REPORT_LABELS.machineHours,
              inputMode: INPUT_MODES.NUMBER,
              description: "",
              value: "",
              notes: "",
              photo: [],
            },
          ];
        JSON.parse(formResponse?.data?.getForm?.formData).forEach(
          (formData: tFormPrompts) => {
            if (formData.type === DEFAULT_FORM_INPUT_TYPES.question) {
              prompts.push({
                type: DEFAULT_FORM_INPUT_TYPES.question,
                label: formData.prompt,
                inputMode: formData.inputMode?.value || "text",
                description: "",
                value: "",
                notes: "",
                photo: [],
              });
            } else {
              formData.prompts?.length && formData.prompts.forEach((prompt) => {
                prompts.push({
                  type: DEFAULT_FORM_INPUT_TYPES.section,
                  title: formData.name,
                  label: prompt.component,
                  description: prompt.description,
                  value: "",
                  notes: "",
                  photo: [],
                });
              });
            }
            setInspectionFormPrompts(prompts);
          }
        );
        if (network.online && company?.id) {
          localStorage.setItem(
            `form_${equipmentData.formId}`,
            JSON.stringify(formResponse)
          );
        }
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      } finally {
        setIsPageLoading(false);
      }
    };

    if (equipmentData?.id && !inspectionFormPrompts.length) {
      getForm();
    }
  }, [company?.id, equipmentData, inspectionFormPrompts.length, network.online]);

  useEffect(() => {
    const storePosition = (position: tGPSPosition) => {
      // hasPosition = true;
      setUserLocation({
        type: LOCATION_TYPES.gps,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.LOCATION,
        JSON.stringify({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
      );
      setIsLocating(false);
    };

    if (isLocating) {
      navigator.geolocation.getCurrentPosition(
        storePosition,
        () => {
          setUserLocation({
            type: coordinates?.latitude
              ? LOCATION_TYPES.lastKnownPosition
              : LOCATION_TYPES.unknown,
            ...coordinates,
          });
          setIsLocating(false);
        },
        { timeout: 10000 }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocating]);

  useEffect(() => {

    const removeMedia = async () => {
      if (!mediaAction || !media) {
        return
      }
      const recordToDelete = media[activeStepIndex].find(
        (el) => el.name === mediaAction.fileWithMeta.file.name
      )?.id
      if (recordToDelete) {
        await deleteRecord(recordToDelete);
        setMedia((prev) => {
          return {
            ...prev,
            [activeStepIndex]: media[activeStepIndex].filter(
              (el) => el.name !== mediaAction.fileWithMeta.file.name
            ),
          };
        });
      }
      setMediaAction(null);
    }

    const addMedia = async () => {
      if (!mediaAction) {
        return
      }
      try {
        const obj = {
          name: mediaAction.fileWithMeta.file.name,
          file: mediaAction.fileWithMeta.file,
          question: activeStepIndex,
          formId: equipmentData.formId,
        };
        const event = await add(obj);
        const photoData = await getByID(event);
        setMedia((prev) => {
          return {
            ...prev,
            [activeStepIndex]: (prev && prev[activeStepIndex])
              ? [
                ...prev[activeStepIndex],
                {
                  id: event,
                  name: mediaAction.fileWithMeta.file.name,
                  data: photoData,
                },
              ]
              : [
                {
                  id: event,
                  name: mediaAction.fileWithMeta.file.name,
                  data: photoData,
                },
              ],
          };
        });
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      }
      setMediaAction(null);
    }

    if (mediaAction && mediaAction.status === "removed") {
      removeMedia();
    }
    if (mediaAction && mediaAction.status === "done") {
      addMedia();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaAction])

  const setLocationFunction = async () => {
    if (navigator.geolocation) {
      setIsLocating(true);
    } else {
      if (coordinates?.latitude) {
        setUserLocation({
          type: LOCATION_TYPES.lastKnownPosition,
          ...coordinates,
        });
      }
    }
  };

  const handleChangeStatus = (fileWithMeta: IFileWithMeta, dropZoneStatus: StatusValue) => {
    if (dropZoneStatus === "removed" || dropZoneStatus === "done") {
      setMediaAction({
        fileWithMeta,
        status: dropZoneStatus
      })
    }
  }

  const isAudioRecordingStopped = (stateOfRecorder: string) => {
    return stateOfRecorder === "stopped" || stateOfRecorder === "idle";
  };

  const getLocationStatusColor = () => {
    switch (userLocation?.type) {
      case LOCATION_TYPES.gps:
        return "green";
      case LOCATION_TYPES.lastKnownPosition:
        return "blue";
      case null:
      default:
        return "grey";
    }
  };
  return (
    <LoadingSpinner
      isLoading={isPageLoading}
      content={
        <ThemeProvider theme={theme}>
          <Grid
            gridDefinition={[
              { colspan: { default: 12 } },
              { colspan: { default: 12 } },
            ]}
          >
            {equipmentData?.name && (
              <Header
                description={
                  <Badge color={`${getLocationStatusColor()}`}>
                    Location:{" "}
                    {userLocation?.type || "Getting coordinates from device..."}
                  </Badge>
                }
                variant="h1"
                actions={<Link onFollow={() => backBtnAction()}>Back</Link>}
              >{`${equipmentData.name}${selectedUnitId ? ` - ${selectedUnitId.label}` : ""
                } Inspection Report`}</Header>
            )}
            {/* <Flex justifyContent="center">
              {equipmentData?.image ? (
                <img
                  className="equipment-image"
                  src={equipmentData?.image}
                  alt={equipmentData?.name}
                />
              ) : (
                <></>
              )}
            </Flex> */}
          </Grid>
          {shouldReviewForm && (
            <ReviewReport
              cancel={() => setShouldReviewForm(false)}
              inspectionFormPrompts={inspectionFormPrompts}
              media={media}
              submit={createInspectionReport}
            />
          )}
          {!shouldReviewForm && (
            <>
              <SpaceBetween size="l">
                <Box color="text-status-success" margin={{ top: "l" }}>
                  Progress
                  <ProgressBar
                    value={
                      (activeStepIndex / inspectionFormPrompts.length) * 100 || 0
                    }
                  // label="Progress"
                  />
                </Box>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Form
                    actions={
                      <SpaceBetween direction="horizontal" size="m">
                        {activeStepIndex > 0 && (
                          <Button
                            variant="normal"
                            onClick={() => {
                              clearBlobUrl();
                              setActiveStepIndex(activeStepIndex - 1);
                            }}
                          >
                            Previous
                          </Button>
                        )}
                        <Button
                          variant="primary"
                          disabled={ // Next button should be disabled if... 
                            !inspectionFormPrompts[activeStepIndex]?.value || // There is no value stored (e.g. "ok") OR ...
                            ((inspectionFormPrompts[activeStepIndex].value ===
                              ITEM_STATUS_CODES.warn ||
                              inspectionFormPrompts[activeStepIndex].value ===
                              ITEM_STATUS_CODES.inop) && // The value is "warn" or "inop" AND ...
                              !(
                                (inspectionFormPrompts[activeStepIndex].notes && inspectionFormPrompts[activeStepIndex].notes.length > 0) || // The user has added notes (1 char min) OR ...
                                (media && media[activeStepIndex] &&
                                  media[activeStepIndex].length > 0) // The user has added audio, video, or an image.
                              ))
                          }
                          loading={isLoading}
                          onClick={() => {
                            clearBlobUrl();
                            if (media && media[activeStepIndex]) {
                              media[activeStepIndex].forEach(async (mediaItem) => {
                                if (!inspectionFormPrompts[activeStepIndex]?.photo?.includes(mediaItem)) {
                                  inspectionFormPrompts[activeStepIndex]?.photo?.push(mediaItem)
                                }
                              });
                            }
                            activeStepIndex < inspectionFormPrompts.length - 1
                              ? setActiveStepIndex(activeStepIndex + 1)
                              : setShouldReviewForm(true);
                          }}
                        >
                          {activeStepIndex < inspectionFormPrompts.length - 1
                            ? "Next"
                            : "Review"}
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    {inspectionFormPrompts.map((prompt, idx) => {
                      if (idx !== activeStepIndex) {
                        return null;
                      }
                      return (
                        <Box
                          key={prompt.label}
                          display={idx === activeStepIndex ? "block" : "none"}
                        >
                          {prompt.type === DEFAULT_FORM_INPUT_TYPES.question ? (
                            <Container>
                              <FormField
                                label={prompt.label}
                                description={prompt.description}
                              >
                                {prompt.inputMode !== INPUT_MODES.TEXTAREA && (
                                  <Input
                                    autoFocus
                                    value={prompt.value}
                                    onChange={({ detail }) => {
                                      const updatePrompts = [
                                        ...inspectionFormPrompts,
                                      ];
                                      updatePrompts[idx].value = detail.value;
                                      setInspectionFormPrompts(updatePrompts);
                                    }}
                                    inputMode={prompt.inputMode as InputMode | undefined}
                                    type={
                                      prompt.inputMode === INPUT_MODES.NUMBER
                                        ? "number"
                                        : "text"
                                    }
                                  />
                                )}
                                {prompt.inputMode === INPUT_MODES.TEXTAREA && (
                                  <Textarea
                                    autoFocus
                                    value={prompt.value}
                                    onChange={({ detail }) => {
                                      const updatePrompts = [
                                        ...inspectionFormPrompts,
                                      ];
                                      updatePrompts[idx].value = detail.value;
                                      setInspectionFormPrompts(updatePrompts);
                                    }}
                                  />
                                )}
                              </FormField>
                            </Container>
                          ) : (
                            <>
                              <Container
                                header={
                                  <Header variant="h2">{prompt.title}</Header>
                                }
                              >
                                <SpaceBetween direction="vertical" size="xxs">
                                  <Box variant="h5">What you are inspecting:</Box>
                                  <div className="flash">
                                    <h3>{prompt.label}</h3>
                                  </div>
                                  <Box variant="p">What you are looking for:</Box>
                                  <div className="flash">
                                    <p>{prompt.description}</p>
                                  </div>
                                  <Grid
                                    gridDefinition={[
                                      { colspan: { "default": 6, s: 3 } },
                                      { colspan: { "default": 6, s: 3 } },
                                      { colspan: { "default": 6, s: 3 } },
                                      { colspan: { "default": 6, s: 3 } },
                                    ]}
                                  >
                                    <MUIButton
                                      variant={
                                        !prompt.value ||
                                          prompt.value === ITEM_STATUS_CODES.ok
                                          ? "contained"
                                          : "outlined"
                                      }
                                      color="success"
                                      style={{
                                        borderRadius: 20,
                                        fontSize: 40,
                                        padding: "20px 10px",
                                        width: "100%",
                                      }}
                                      onClick={() => {
                                        const updatePrompts = [
                                          ...inspectionFormPrompts,
                                        ];
                                        updatePrompts[idx].value =
                                          ITEM_STATUS_CODES.ok;
                                        setInspectionFormPrompts(updatePrompts);
                                        activeStepIndex <
                                          inspectionFormPrompts.length - 1
                                          ? setActiveStepIndex(
                                            activeStepIndex + 1
                                          )
                                          : setShouldReviewForm(true);
                                      }}
                                    >
                                      <Icon name="status-positive" size="medium" />
                                    </MUIButton>
                                    <MUIButton
                                      variant={
                                        !prompt.value ||
                                          prompt.value === ITEM_STATUS_CODES.warn
                                          ? "contained"
                                          : "outlined"
                                      }
                                      color="secondary"
                                      style={{
                                        borderRadius: 20,
                                        fontSize: 40,
                                        padding: "20px 10px",
                                        width: "100%",
                                      }}
                                      onClick={() => {
                                        const updatePrompts = [
                                          ...inspectionFormPrompts,
                                        ];
                                        updatePrompts[idx].value =
                                          ITEM_STATUS_CODES.warn;
                                        setInspectionFormPrompts(updatePrompts);
                                      }}
                                    >
                                      <Icon name="status-warning" size="medium" />
                                    </MUIButton>
                                    <MUIButton
                                      variant={
                                        !prompt.value ||
                                          prompt.value === ITEM_STATUS_CODES.inop
                                          ? "contained"
                                          : "outlined"
                                      }
                                      color="error"
                                      style={{
                                        borderRadius: 20,
                                        padding: "20px 10px",
                                        fontSize: 40,
                                        width: "100%",
                                      }}
                                      onClick={() => {
                                        const updatePrompts = [
                                          ...inspectionFormPrompts,
                                        ];
                                        updatePrompts[idx].value =
                                          ITEM_STATUS_CODES.inop;
                                        setInspectionFormPrompts(updatePrompts);
                                      }}
                                    >
                                      <Icon name="status-negative" size="medium" />
                                    </MUIButton>
                                    <MUIButton
                                      variant={
                                        !prompt.value ||
                                          prompt.value === ITEM_STATUS_CODES.na
                                          ? "contained"
                                          : "outlined"
                                      }
                                      color="primary"
                                      style={{
                                        borderRadius: 20,
                                        fontSize: 40,
                                        padding: "20px 10px",
                                        width: "100%",
                                      }}
                                      onClick={() => {
                                        const updatePrompts = [
                                          ...inspectionFormPrompts,
                                        ];
                                        updatePrompts[idx].value =
                                          ITEM_STATUS_CODES.na;
                                        setInspectionFormPrompts(updatePrompts);
                                        activeStepIndex <
                                          inspectionFormPrompts.length - 1
                                          ? setActiveStepIndex(
                                            activeStepIndex + 1
                                          )
                                          : setShouldReviewForm(true);
                                      }}
                                    >

                                      <Icon url="/not-applicable-svg-logo.svg" size="medium" />

                                    </MUIButton>
                                  </Grid>
                                  {inspectionFormPrompts[idx].value &&
                                    inspectionFormPrompts[idx].value !==
                                    ITEM_STATUS_CODES.ok && (
                                      <>
                                        <FormField label="Notes">
                                          <Textarea
                                            value={prompt.notes || ''}
                                            onChange={({ detail }) => {
                                              const updatePrompts = [
                                                ...inspectionFormPrompts,
                                              ];
                                              updatePrompts[idx].notes =
                                                detail.value;
                                              setInspectionFormPrompts(
                                                updatePrompts
                                              );
                                            }}
                                          />
                                        </FormField>
                                        <Container
                                          header={<h2>Add Audio Note</h2>}
                                        >
                                          <SpaceBetween
                                            direction="horizontal"
                                            size="xxl"
                                          >
                                            {isAudioRecordingStopped(status) && (
                                              <Button
                                                onClick={startRecording}
                                                iconAlign="left"
                                                iconName="microphone"
                                              >
                                                Record
                                              </Button>
                                            )}
                                            {!isAudioRecordingStopped(status) && (
                                              <Button
                                                onClick={stopRecording}
                                                loading={
                                                  status === "acquiring_media"
                                                }
                                                iconAlign="left"
                                                iconName="microphone-off"
                                              >
                                                {status === "recording" && (
                                                  <>End Recording</>
                                                )}
                                                {status === "acquiring_media" && (
                                                  <>Starting</>
                                                )}
                                              </Button>
                                            )}
                                            {audioReview[activeStepIndex] && (
                                              <>
                                                <audio
                                                  src={URL.createObjectURL(
                                                    audioReview[activeStepIndex]
                                                  )}
                                                  controls
                                                >
                                                  <source
                                                    src={URL.createObjectURL(
                                                      audioReview[activeStepIndex]
                                                    )}
                                                    type="audio/wav"
                                                  />
                                                </audio>
                                                <Button
                                                  iconAlign="left"
                                                  iconName="close"
                                                  onClick={() => {
                                                    const newMedia = { ...media };
                                                    delete newMedia[
                                                      activeStepIndex
                                                    ];
                                                    setMedia(newMedia);
                                                    const newAudioReview = {
                                                      ...audioReview,
                                                    };
                                                    delete newAudioReview[
                                                      activeStepIndex
                                                    ];
                                                    setAudioReview(
                                                      newAudioReview
                                                    );
                                                  }}
                                                >
                                                  Remove
                                                </Button>
                                              </>
                                            )}
                                          </SpaceBetween>
                                        </Container>
                                        <Dropzone
                                          addClassNames={'video-dropzone' as IStyleCustomization<string>}
                                          onChangeStatus={handleChangeStatus}
                                          accept="image/jpeg, image/jpg, image/png, audio/wav, audio/mp3, video/*"
                                          inputContent={(_files, extra) => {
                                            if (extra.reject) {
                                              return "Image or video files only";
                                            }
                                            return "Click to add images or videos";
                                          }}
                                        />
                                      </>
                                    )}
                                  <Box>
                                    <SpaceBetween direction="horizontal" size="l">
                                      {prompt?.photo?.map((photo, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="photo-container"
                                          >
                                            <img
                                              key={index}
                                              src={URL.createObjectURL(photo?.data?.file as unknown as MediaSource)}
                                              alt={prompt.label}
                                              className="photo"
                                            />
                                            <div className="delete-image">
                                              <Button
                                                variant="icon"
                                                iconName="close"
                                                onClick={async () => {
                                                  await deleteRecord(photo?.id as Key)
                                                  const updatedFormPrompts = [
                                                    ...inspectionFormPrompts,
                                                  ];
                                                  updatedFormPrompts[
                                                    idx
                                                  ].photo?.splice(index, 1);
                                                  if (media) {
                                                    setMedia((prev) => {
                                                      return {
                                                        ...prev,
                                                        [activeStepIndex]: media[activeStepIndex].filter(
                                                          (el) => el.id !== photo?.id
                                                        ),
                                                      };
                                                    });
                                                  }
                                                  setInspectionFormPrompts(
                                                    updatedFormPrompts
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        )
                                      })}
                                    </SpaceBetween>
                                  </Box>
                                </SpaceBetween>
                              </Container>
                            </>
                          )}
                        </Box>
                      );
                    })}
                  </Form>
                </form>
              </SpaceBetween>
            </>
          )}
        </ThemeProvider>
      }
    />
  );
};
