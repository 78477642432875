import {
    Card,
    Image,
    Heading,
    Flex,
    Text,
} from '@aws-amplify/ui-react';
import { useUserContext } from "../contexts/UserContext";
import { useCompanyContext } from '../contexts/CompanyContext';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Header } from '@cloudscape-design/components';

/**
 *React component where we can update company specific bio and colors and images/icons and then use them elsewhere in the application - hopefully one single place to simplify additions of new companies. 
 * */
export const CompanyInfoUser = () => {
    const { company } = useUserContext();
    const { company: companyContext } = useCompanyContext()
    const [currentCompany, setCurrentCompany] = useState(company)

    useEffect(() => {
        if (companyContext !== null) setCurrentCompany(companyContext)

    }, [companyContext])

    return (
        <Card variation='outlined'>
            <Flex direction="column" justifyContent="center" alignContent="center" alignItems="center">
                <Flex
                    direction="column"
                    alignItems="center"
                >
                    <Image
                        src={currentCompany?.logo ? currentCompany.logo : "./site-admin-img.png"}
                        alt="company logo"
                        maxHeight="300px"
                        id="page-top"
                    />
                </Flex>
                <Flex
                    direction="column"
                    justifyContent="flex-start"
                >

                    <Heading level={4}>
                        {currentCompany?.name ? currentCompany.name : <Header>No Company Found</Header>}
                    </Heading>

                    <Text as="span" fontStyle="italic">
                        {currentCompany?.type ? currentCompany.type : ""}
                    </Text>
                    <Text as='span' fontSize="1.2em">
                        {currentCompany?.description ? currentCompany.description : ""}
                    </Text>
                </Flex>
            </Flex>
        </Card >
    )

}


export const CompanyInfoSuperAdmin = () => {
    const { company } = useUserContext();
    const { company: companyContext } = useCompanyContext()
    const navigate = useNavigate()
    const [currentCompany, setCurrentCompany] = useState(company)
    useEffect(() => {
        if (companyContext !== null) setCurrentCompany(companyContext)

    }, [companyContext])

    return (
        <Card variation='outlined'>
            <Flex direction="column" justifyContent="center" alignContent="center" alignItems="center">
                <Flex
                    direction="column"
                    alignItems="center"
                >
                    <Image
                        src={currentCompany?.logo ? currentCompany.logo : "./site-admin-img.png"}
                        alt="company logo"
                        maxHeight="300px"
                        id="page-top"
                    />
                </Flex>
                <Flex
                    direction="column"
                    justifyContent="flex-start"
                >

                    <Heading level={4}>
                        {currentCompany?.name ? currentCompany.name : <Button onClick={() => navigate("/admin", { replace: true })}>Please Select Current Company</Button>}
                    </Heading>

                    <Text as="span" fontStyle="italic">
                        {currentCompany?.type ? currentCompany.type : ""}
                    </Text>
                    <Text as='span' fontSize="1.2em">
                        {currentCompany?.description ? currentCompany.description : ""}
                    </Text>
                </Flex>
            </Flex>
        </Card >

    )

}