import { Button as AWSButton, Flex } from "@aws-amplify/ui-react";
import {
    Header,
    Box,
    Container,
    SpaceBetween
} from "@cloudscape-design/components";
import { signOut } from "aws-amplify/auth";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../contexts/UserContext";


//upgrade subscription handle the case where someone navigating from super admin to this page will need the return back button to send them to "/admin" but if they're not a super admin, just a normal admin with manage users permissions it will redirect them back to their user dashboard "/".

export const UpgradeSubscription = () => {
    const navigate = useNavigate();
    const { companyUser } = useUserContext();
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false)

    useEffect(() => {
        console.log('company user')
        if (companyUser) {
            setIsSuperAdmin(false)
        } else {
            setIsSuperAdmin(true)
        }
    })

    return (
        <>
            <div style={{ paddingTop: '100px' }}>
                <Flex direction="column" gap="xxl" style={{ position: 'absolute', top: "20%", left: "50%", transform: "translateX(-50%)" }}>
                    <Container>
                        <SpaceBetween size="m">
                            <Box>
                                <Header variant="h1">Package Pricing</Header>
                                <br />
                                Please request information about subscription tiers
                            </Box>
                            <Box>
                                <Header variant="h3">Contact Info</Header>
                                Mark Stratton
                                <br />
                                Email:
                                <a href="mailto:markstratton05@gmail.com">{" "}markstratton05@gmail.com</a>
                                <br />
                                Phone:
                                <a href="tel:+14326613606">{" "}(432) 661-3606</a>
                            </Box>
                        </SpaceBetween>
                    </Container>
                    <AWSButton
                        loadingText=""
                        backgroundColor="gray"
                        onClick={() => isSuperAdmin ? navigate("/admin", { replace: true }) : navigate("/", { replace: true })}
                    >
                        Back
                    </AWSButton>
                </Flex>
            </div>
        </>
    );
};


//Manage subscription is for links on the super admin dashboard where the navigation from the back button needs to return the user to the dashbaord "/" page 

export const ManageSubscription = () => {
    const navigate = useNavigate();

    return (
        <>
            <div style={{ paddingTop: '100px' }}>

                <Flex direction="column" gap="xxl" style={{ position: 'absolute', top: "20%", left: "50%", transform: "translateX(-50%)" }}>
                    <Container>
                        <SpaceBetween size="m">
                            <Box>
                                <Header variant="h1">Package Pricing</Header>
                                <br />
                                Request Information About Subscription Management Here!
                            </Box>
                            <Box>
                                <Header variant="h3">Contact Info</Header>
                                Mark Stratton
                                <br />
                                Email:
                                <a href="mailto:markstratton05@gmail.com">{" "}markstratton05@gmail.com</a>
                                <br />
                                Phone:
                                <a href="tel:+14326613606">{" "}(432) 661-3606</a>
                            </Box>
                        </SpaceBetween>
                    </Container>
                    <AWSButton
                        loadingText=""
                        backgroundColor="gray"
                        onClick={() => navigate("/", { replace: true })}
                    >
                        Back
                    </AWSButton>
                </Flex>
            </div>

        </>
    );
};

//this function handles the screen individuals are redirected to if they are found to have a correct cognito login but are no longer associated with any entry in Dynamo (ie a deleted CompanyOwner, or user from a deleted Company)
export const AccountStatus = () => {
    const navigate = useNavigate();


    return (
        <>
            <div style={{ paddingTop: '100px' }}>

                <Flex direction="column" gap="xxl" style={{ position: 'absolute', top: "20%", left: "50%", transform: "translateX(-50%)" }}>
                    <Container>
                        <SpaceBetween size="m">
                            <Box>
                                <Header variant="h1">Account Not Found</Header>
                                <br />
                                Please reach out to Mark Stratton if this is found in error or to create a new account.
                            </Box>
                            <Box>
                                <Header variant="h3">Contact Info</Header>
                                Mark Stratton
                                <br />
                                Email:
                                <a href="mailto:markstratton05@gmail.com">{" "}markstratton05@gmail.com</a>
                                <br />
                                Phone:
                                <a href="tel:+14326613606">{" "}(432) 661-3606</a>
                            </Box>
                        </SpaceBetween>
                    </Container>
                    <AWSButton
                        loadingText=""
                        backgroundColor="gray"
                        onClick={() => {
                            signOut()
                            navigate("/", { replace: true })
                        }
                        }
                    >
                        Exit
                    </AWSButton>
                </Flex>
            </div>

        </>
    );
};
