import { useEffect } from "react";
import { Box } from "@cloudscape-design/components";
import { SignInHeader } from "../components/Auth/SignInHeader/SignInHeader";
import { SignInFooter } from "../components/Auth/SignInFooter/SignInFooter";

import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from "react-router";
import { CreateCompanyOwnerInput } from "../API";
import { generateClient } from "aws-amplify/api";
import { createCompanyOwner } from "../graphql/mutations";
import { fetchUserAttributes } from "aws-amplify/auth";
import { SUPER_ADMIN_USER_PERMISSIONS } from "../utils/constants";
const client = generateClient({ authMode: "userPool" });

export const SuperAdminSignUp = () => {
    const { user, route } = useAuthenticator((context) => [
        context.route,
    ]);
    // const location = useLocation();
    const navigate = useNavigate();
    const { toForgotPassword } = useAuthenticator();
    //TODO: add a check to location in URL parameters to see if they're coming from register-full access or register limited-access, this URL will be given to them by Mark if they're signing up without a free demo, or if they're upgrading they'll be given the URL when they choose which subscription tier to pay for (and pay Mark)


    // const from = location.state?.from?.pathname ? `${location.state?.from?.pathname}${location.state?.from?.search}` : "/register/new-company";
    // useEffect(() => {
    //     if (route === "authenticated") {
    //         navigate(from, { replace: true });
    //     }
    // }, [route, navigate, from]);

    useEffect(() => {

        const updateUserWithCognitoId = async () => {
            try {
                // console.log('auth', Auth)
                const attributes = await fetchUserAttributes();
                const email = attributes.email as string
                const name = attributes.name as string
                const cognitoId = attributes.sub as string

                // 
                // Save the Cognito ID to DynamoDB
                await saveToDynamoDB(cognitoId, email, name);


            } catch (error) {
                console.error("Error fetching user details:", error);
            } finally {
                // Navigate to admin page
                navigate("/admin", { replace: true });
            }
        };

        if (route === "authenticated") {
            updateUserWithCognitoId();
        }

    }, [navigate, route, user]);

    const saveToDynamoDB = async (cognitoId: string, email: string, name: string) => {
        const input: CreateCompanyOwnerInput = {
            id: cognitoId,
            email: email,
            subscriptionType: "paid-full-access",
            name: name,
            siteAdmin: false,
            role: "super-admin",
            permissions: JSON.stringify(
                SUPER_ADMIN_USER_PERMISSIONS
            ),
        }

        try {

            const response = await client.graphql({
                query: createCompanyOwner,
                variables: { input },
            });
        } catch (error) {
            console.error('Error saving to DynamoDB:', error);
        }
    };


    const components = {
        SignIn: {
            Header() {
                return <SignInHeader />;
            },
            Footer() {
                return (
                    <SignInFooter
                        toForgotPassword={toForgotPassword}
                        forgotPasswordText="Forgot Password"
                    />
                );
            },
        },
    };
    const formFields = {
        signUp: {
            name: {
                placeholder: "Enter Your Name Here",
                isRequired: true,
                label: "Name:",
            },
        },
    }
        ;
    return (
        <View className="auth-wrapper centered-wrapper">
            <Box textAlign="center">
                <img
                    className="centered-logo"
                    src="/ei_logo.jpg"
                    alt="logo"
                    width="300"
                />
            </Box>
            <Authenticator
                key={"signUp"}
                initialState={"signUp"}
                components={components}
                formFields={formFields}
                loginMechanisms={["email"]}
            ></Authenticator>
        </View>
    );
};
