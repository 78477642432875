import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router';
import { signOut } from 'aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import TopNavigation from '@cloudscape-design/components/top-navigation';
import { useUserContext } from '../contexts/UserContext';

import { getBaseRoute } from '../utils/helperFunctions';
import { useWindowScroll } from "@uidotdev/usehooks";
import { PERMISSION_VALUES } from '../utils/constants';
import useLink from '../hooks/useLink';

// importing modal and related components
import { Modal, Box, SpaceBetween, Button } from "@cloudscape-design/components";
import { useCompanyContext } from "../contexts/CompanyContext";

export const Navbar = () => {
  //TODO: manage admin privliges better, maybe just using role!
  const [isOpenLogout, setIsOpenLogout] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const baseRoute = getBaseRoute(location.pathname);
  const [{ x, y }, scrollTo] = useWindowScroll();
  const { handleFollow } = useLink();
  const { user } = useAuthenticator((context) => {
    return [context.user];
  });
  const { companyUser, company, noNavBar, setCompanyUser, setCompany } = useUserContext();
  const { company: companyContext } = useCompanyContext();
  const [pausedSubscription, setPausedSubscription] = useState<boolean>(false)

  useEffect(() => {
    if (company !== null) {
      companyContext ? setPausedSubscription(companyContext.subscriptionType === "paused") :
        setPausedSubscription(company.subscriptionType === "paused")
    }
  }, [company, companyContext])

  const routesWithBackButton = ["login", "pricing", "free-demo", "account", "register"];

  const userRoutes = () => {
    if (pausedSubscription || !companyUser) return [];
    if (companyUser) {
      return [
        {
          type: "button",
          text: "Users",
          permission: PERMISSION_VALUES.MANAGE_USERS,
          onClick: (e: { preventDefault: () => void; }) => handleFollow(e, "/users"),
        },
        {
          type: "button",
          text: "Forms",
          permission: PERMISSION_VALUES.MANAGE_FORMS,
          onClick: (e: { preventDefault: () => void; }) => handleFollow(e, "/forms"),
        },
        {
          type: "button",
          text: "Equipment",
          permission: PERMISSION_VALUES.MANAGE_EQUIPMENT,
          onClick: (e: { preventDefault: () => void; }) => handleFollow(e, "/equipment"),
        },
        {
          type: "button",
          text: "Inspection Reports",
          permission: PERMISSION_VALUES.VIEW_REPORTS,
          onClick: (e: { preventDefault: () => void; }) => handleFollow(e, "/reports"),
        },
        {
          type: "button",
          text: "Issues",
          permission: PERMISSION_VALUES.VIEW_DISCREPANCIES,
          onClick: (e: { preventDefault: () => void; }) => handleFollow(e, "/issues"),
        },
      ].filter((route) => companyUser.permissions?.includes(route.permission))
        .map(({ type, text, onClick }) => {
          return {
            type,
            text,
            onClick,
          };
        });
    } return []
  };

  const logo = { alt: 'home', src: "/home-svgrepo-white.svg" };
  const mainIdentity = {
    href: "/",
    title: company?.name ? company.name : "Equipment Inspection",
    logo,
    onFollow: (e: { preventDefault: () => void; }) => {
      e.preventDefault();
      navigate("/");
    },
  };

  const adminRoutes = () => {
    if (!companyUser) {
      return [
        {
          type: "button",
          text: "Company Admin",
          permission: PERMISSION_VALUES.SUPER_ADMIN,
          onClick: (e: { preventDefault: () => void; }) => handleFollow(e, "/admin"),
        },
      ]
    } return []
  };

  const splashPageRoutes = () => {
    return routesWithBackButton.includes(baseRoute) ? [] : [
      {
        type: "button",
        text: "Our Inspection Solution",
        onClick: () => scrollTo({ left: 0, top: 700, behavior: "smooth" }),
      },
      {
        type: "button",
        text: "About Us",
        onClick: () => scrollTo({ left: 0, top: 1200, behavior: "smooth" }),
      },
      {
        type: "button",
        text: "",
        onClick: () => scrollTo({ left: 0, top: 5000, behavior: "smooth" }),
      },
    ]
  }



  const links = user?.userId
    ? [
      ...adminRoutes(),
      ...userRoutes(),

      //   ...navLinks(),
      {
        type: 'menu-dropdown',
        text: companyUser?.name || user.username,
        description: companyUser?.email || 'Super Admin Role',
        iconName: 'user-profile',
        onItemClick: (e: { detail: { id: string; }; }) => {
          if (e.detail.id === 'logout') {
            setIsOpenLogout(true);
          }
        },
        items: [
          {
            id: 'logout',
            text: 'Log out',
          },
        ],
      },
    ]
    : [
      ...splashPageRoutes(),
      routesWithBackButton.includes(baseRoute) ? {
        type: "button",
        text: "Back",
        href: "/",
        onClick: (e: { preventDefault: () => void; }) => handleFollow(e, "/"),
      } :
        {
          type: "button",
          text: "Log In",
          href: "/login",
          onClick: () => navigate("/login")
        },
    ];

  // if (baseRoute === 'register' && !user?.username) {
  //   links.length = 0;
  // }
  // if (baseRoute === 'free-demo' && !user?.username) {
  //   links.length = 0;
  // }

  // if (baseRoute === 'pricing' && !user?.username) {
  //   links.length = 0;
  // }
  // if (baseRoute === 'account') {
  //   links.length = 0;
  // }

  // handle logout logic from modal-dialog
  const handleLogout = async () => {
    setCompanyUser(null);
    setCompany(null)
    localStorage.removeItem("storedCompanyId")
    localStorage.removeItem("storedCompanySubscriptionType")
    localStorage.removeItem("storedCompanyName")
    await signOut();
    setIsOpenLogout(false);
    navigate('/', { replace: true });
  }

  return (
    <div className="hide-from-printer">
      {!noNavBar && user?.userId && (
        <TopNavigation
          identity={mainIdentity}
          utilities={links as never}
          i18nStrings={{
            searchIconAriaLabel: "Search",
            searchDismissIconAriaLabel: "Close search",
            overflowMenuTriggerText: "More",
            overflowMenuTitleText: "Other Menu Items",
            overflowMenuBackIconAriaLabel: "Back",
            overflowMenuDismissIconAriaLabel: "Close menu",
          }}
        />
      )}
      {!noNavBar && !user?.userId && (
        <div style={{ width: "100%", position: 'fixed', top: 0, zIndex: 1002 }}>
          <TopNavigation
            identity={mainIdentity}
            utilities={links as never}
            i18nStrings={{
              searchIconAriaLabel: "Search",
              searchDismissIconAriaLabel: "Close search",
              overflowMenuTriggerText: "More",
              overflowMenuTitleText: "Other Menu Items",
              overflowMenuBackIconAriaLabel: "Back",
              overflowMenuDismissIconAriaLabel: "Close menu",
            }}
          />
        </div>
      )}
      <Modal
        onDismiss={() => setIsOpenLogout(false)}
        visible={isOpenLogout}
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => setIsOpenLogout(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Logging out"
      >
        Are you sure you want to logout?
      </Modal>
    </div>
  );
};
