import { useState, useEffect } from 'react';
import { Image, Flex, Card as AWSCard, Heading, Collection, View, Text, Button as AWSButton } from '@aws-amplify/ui-react';
import { Box, Button, SpaceBetween } from "@cloudscape-design/components"
import { useWindowScroll, useNetworkState } from "@uidotdev/usehooks";
import { Card, CardMedia } from '@mui/material';
import "../css/SplashPage.css"
import { useNavigate } from 'react-router-dom';


const DemoButton = () => {
    const navigate = useNavigate();
    const handleFreeDemoClick = () => {
        navigate("/register/free-demo", { replace: true })
        window.location.reload();
    }
    const handlePricingClick = () => {
        navigate("/pricing", { replace: true })
    }

    return (
        <>
            <SpaceBetween direction="horizontal" size="m">

                <AWSButton
                    backgroundColor="yellow"
                    loadingText=""
                    onClick={handleFreeDemoClick}
                >
                    Free Demo
                </AWSButton>
                <AWSButton
                    backgroundColor="green"
                    color="white"
                    loadingText=""
                    onClick={handlePricingClick}
                >
                    Contact for Pricing
                </AWSButton>
            </SpaceBetween>
        </>
    )
}

export const SplashPage = () => {
    const [isScrollBtnVisible, setIsScrollBtnVisible] = useState<boolean>(false);
    const [{ x, y }, scrollTo] = useWindowScroll();
    const network = useNetworkState();

    const toggleVisibility = (): void => {
        if (window.scrollY > 100) {
            setIsScrollBtnVisible(true);
        } else {
            setIsScrollBtnVisible(false);
        }
    };



    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    const scrollToTop = (): void => {
        scrollTo({ top: 0, behavior: 'smooth' });
    };

    const aboutInfoCardItems = [
        {
            title: 'Inspect Equipment',
            src: "/QR-code-scanning.jpg",
            alt: "image of qr code",
            text: "Scan a QR code and users are taken immediately to the equipment's inspection form - Inspect with confidence using our mobile friendly inspection system"
        },
        {
            title: 'Customized Forms',
            src: "/inspection-form.png",
            alt: "",
            text: "Add and remove equipment, create unique inspection forms for each type of equipment - specific to your company.  No more paper books getting destroyed."
        },
        {
            title: 'Company Wide Access',
            src: "/company-wide-comprehensive-edited.jpg",
            alt: "group shot of a construction company",
            text: "Approved users are notified when assets are down with major issues - Keeping your people accountable for repairs and maintenance - If the equipment isn't running, it can't make money!"
        },
        {
            title: 'Reporting',
            src: "/reporting-image.png",
            alt: "logo saying reports",
            text: "Instant access to reports from anywhere you have a mobile connection - View reports by location or by type -  Download and archive reports for review or scheduling services."
        },
    ];

    const textSectionCardItems = [
        {
            title: 'Fully Customizable',
            text: "Equipment Inspection Forms easy to use platform gives you the tools to customize forms for your company equipment - No more generic forms for all your equipment",
        },
        {
            title: 'Industry Compliant',
            text: "With Equipment Inspection Forms you'll have full confidence in meeting industry regulatory requirements",
        },
        {
            title: 'Real Time Reporting',
            text: "Our digital platform lets you track inspections with GPS mapping.  Safety issues are uploaded in real time.  Beyond that, it helps you identify potential down time and pinpoint repairs and services before they become breakdowns.",
        },
    ];

    return (
        <>
            <div className='splashPageDesktop'>
                <Flex direction="column" gap="xxl" style={{ position: 'relative', top: "30px" }}>
                    {network.online ? (
                        <Card className="splash-video-parent">
                            <CardMedia
                                className='video'
                                component="video"
                                width="100%"
                                src="/splash-video.mp4"
                                autoPlay
                                muted
                                loop
                                preload='auto'
                                poster='splash-static-equipment-image.jpg'
                            />
                            <div className='splash-header-div'>
                                Easy to use equipment inspection forms - Fully customizable to your equipment - Eliminates paper books - Reduce equipment downtime with real time reporting
                            </div>
                            <div className="splash-logo-div">
                                <Image
                                    width="20%"
                                    objectFit="cover"
                                    src="/Equipmentinspectionforms.com-transparent-logo.png"
                                    alt="image of Quarry operations"
                                />
                            </div>
                            <div className="video-demo-btn">
                                <DemoButton />
                            </div>

                        </Card>
                    ) : (
                        <Image
                            width="50%"
                            objectFit="cover"
                            objectPosition="50% 50%"
                            src="/splash-static-equipment-image.jpg"
                            alt="image of Quarry operations"
                        />
                    )}
                    <div className='text-parent'>
                        <Collection
                            items={textSectionCardItems}
                            type="list"
                            direction="row"
                            justifyContent="center"
                            gap="1rem"
                            wrap="wrap"
                            width="100%"
                            paddingTop="large"
                            paddingBottom="medium"
                            backgroundColor="blue.20"
                            minHeight="500px"
                        >
                            {(item, index) => (
                                <AWSCard
                                    key={index}
                                    borderRadius="medium"
                                    backgroundColor="blue.20"
                                    // variation="outlined"
                                    width="350px"
                                    minHeight="300px"
                                >
                                    <View marginTop="medium" padding="xs" textAlign="center">
                                        <Heading padding="medium">{item.title}</Heading>
                                        <Text padding="medium">
                                            {item.text}
                                        </Text>
                                    </View>
                                </AWSCard>
                            )}
                        </Collection>
                        <div className="text-demo-btn">
                            <DemoButton />
                        </div>
                    </div>
                    <Collection
                        items={aboutInfoCardItems}
                        type="list"
                        direction="row"
                        justifyContent="center"
                        gap="20px"
                        wrap="wrap"
                    >
                        {(item, index) => (
                            <AWSCard key={index}
                                borderRadius="medium"
                                width="600px"
                                variation="outlined">
                                <Flex direction="row" alignItems="flex-start">
                                    <Image
                                        alt={item.alt}
                                        src={item.src}
                                        width="33%"
                                    />
                                    <Flex
                                        direction="column"
                                        alignItems="flex-start"
                                    >

                                        <Heading level={5}>
                                            {item.title}
                                        </Heading>

                                        <Text as="span">
                                            {item.text}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </AWSCard>
                        )}
                    </Collection>

                </Flex >
                {isScrollBtnVisible && <div style={{
                    position: 'fixed',
                    bottom: '5%',
                    right: '2%',
                    zIndex: 1002
                }}>
                    <Button onClick={scrollToTop}>Back To Top</Button>
                </div>
                }
            </div>
            <div className='splashPageMobile'>
                <Flex direction="column" gap="xxl" style={{ position: 'relative', top: "30px" }}>
                    {network.online ? (
                        <>
                            <Card className="splash-video-parent">
                                <CardMedia
                                    className='video'
                                    component="video"
                                    width="100%"
                                    src="/splash-video.mp4"
                                    autoPlay
                                    muted
                                    loop
                                    preload='auto'
                                    poster='splash-static-equipment-image.jpg'
                                />
                            </Card>
                            <SpaceBetween size='m'>
                                <Box>
                                    <Image
                                        width="20%"
                                        objectFit="cover"
                                        src="/Equipmentinspectionforms.com-transparent-logo.png"
                                        alt="image of Quarry operations"
                                    />
                                    <div className="demo-btn">

                                        <DemoButton />
                                    </div>
                                </Box>
                                <Box>
                                    <div className='header-div'>
                                        Easy to use equipment inspection forms - Fully customizable to your equipment - Eliminates paper books - Reduce equipment downtime with real time reporting
                                    </div>
                                </Box>
                            </SpaceBetween>
                        </>
                    ) : (
                        <Image
                            width="50%"
                            objectFit="cover"
                            objectPosition="50% 50%"
                            src="/splash-static-equipment-image.jpg"
                            alt="image of Quarry operations"
                        />
                    )}
                    <div >
                        <Collection
                            items={textSectionCardItems}
                            type="list"
                            direction="column"
                            border="blue"

                            wrap="wrap"
                            width="100%"

                            backgroundColor="blue.20"

                        >
                            {(item, index) => (
                                <AWSCard
                                    key={index}
                                    borderRadius="medium"
                                    backgroundColor="blue.20"
                                    variation="outlined"
                                    margin="24px"

                                >
                                    <View marginTop="medium" padding="xs" textAlign="center">
                                        <Heading padding="medium">{item.title}</Heading>
                                        <Text padding="medium">
                                            {item.text}
                                        </Text>
                                    </View>
                                </AWSCard>
                            )}
                        </Collection>
                    </div>
                    <div className="demo-btn">
                        <DemoButton />
                    </div>
                    <Collection
                        items={aboutInfoCardItems}
                        type="list"
                        direction="row"
                        justifyContent="center"
                        gap="20px"
                        wrap="wrap"
                    >
                        {(item, index) => (
                            <AWSCard key={index}
                                borderRadius="medium"
                                width="600px"
                                variation="outlined">
                                <Flex direction="row" alignItems="flex-start">
                                    <Image
                                        alt={item.alt}
                                        src={item.src}
                                        width="50%"
                                    />
                                    <Flex
                                        direction="column"
                                        alignItems="flex-start"
                                    >

                                        <Heading level={5}>
                                            {item.title}
                                        </Heading>

                                        <Text as="span">
                                            {item.text}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </AWSCard>
                        )}
                    </Collection>

                </Flex >
                {isScrollBtnVisible && <div style={{
                    position: 'fixed',
                    bottom: '5%',
                    right: '2%',
                    zIndex: 1002
                }}>
                    <Button onClick={scrollToTop}>Back To Top</Button>
                </div>
                }
            </div>
        </>
    )
};
