import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import {
  Box,
  Button,
  Form,
  FormField,
  Input,
  Header,
  Link,
  Modal,
  SpaceBetween,
  Table,
  FileUpload,
  Textarea,
  Icon,
  Flashbar,
} from "@cloudscape-design/components";
import * as Sentry from "@sentry/browser";
import {
  createCompany as createCompanyMutation,
  deleteCompany as deleteCompanyMutation,
  updateCompany as updateCompanyMutation,
} from "../graphql/mutations";
import {
  listCompanies as listCompaniesQuery,
  listCompanyOwners as listCompanyOwnersQuery
} from "../graphql/queries";
import { useCompanyContext } from "../contexts/CompanyContext";
import { Company, CreateCompanyInput, UpdateCompanyInput } from "../API";
import { resizeLogo, base64ToBlob, getEarliestDate, formatDate, willExpireWithinAWeek, isDateAfter, getEarliestDateAndCompany, isDateInFuture } from "../utils/helperFunctions";
import { Button as AWSButton, useAuthenticator } from "@aws-amplify/ui-react";
import { MAX_NUMBER_COMPANY_FREE_DEMO } from "../utils/constants";
import { disable } from "aws-amplify/analytics";


const client = generateClient({ authMode: "userPool" });

interface MyObject {
  subscriptionExpiration?: string;
  name?: string;
}

export const CompanyAdmin = () => {
  const [companyNameInForm, setCompanyNameInForm] = useState("");
  const [companyLogoInForm, setCompanyLogoInForm] = useState<Blob[] | []>([]);;
  const [companyDescriptionInForm, setCompanyDescriptionInForm] = useState("");
  const [companyTypeInForm, setCompanyTypeInForm] = useState("");
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const [companyDataByOwner, setCompanyDataByOwner] = useState<Company[]>([]);
  const [companyToDelete, setCompanyToDelete] = useState<Company | null>(null);
  const [companiesToEdit, setCompaniesToEdit] = useState<Company[]>([]);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [maxNumberUsers, setMaxNumberUsers] = useState(99999)
  const [maxNumberEquipment, setMaxNumberEquipment] = useState(99999)
  const [isLoading, setIsLoading] = useState(false);
  const { setCompany } = useCompanyContext();
  const { company: currentCompany } = useCompanyContext();
  const { user, route } = useAuthenticator((context) => [
    context.user,
    context.route,
  ]);

  const [freeDemoCapacityMet, setFreeDemoCapacityMet] = useState<boolean>(false)
  const [isFreeDemo, setIsFreeDemo] = useState<boolean>(false)
  const [pausedSubscription, setPausedSubscription] = useState<boolean>(false)
  const [willExpire, setWillExpire] = useState<boolean>(false)
  const [isExpired, setIsExpired] = useState<boolean>(false)
  const [expirationDate, setExpirationDate] = useState<string>("")
  const [companyExpirationData, setCompanyExpirationData] = useState<MyObject>({})
  const [isAddCompanyModelOpen, setIsAddCompanyModelOpen] = useState(false);
  const [isEditCompanyModelOpen, setIsEditCompanyModelOpen] = useState(false);
  const [isSubscriptionExpiredModalOpen, setIsSubscriptionExpiredModalOpen] = useState(false)

  const navigate = useNavigate();

  const getCompanyData = async () => {
    setIsLoading(true);
    try {
      const response = await client.graphql({
        query: listCompaniesQuery,
      });
      const allCompaniesData =
        response?.data?.listCompanies?.items.sort((a, b) =>
          a.name > b.name ? 1 : -1
        ) || null;
      // setCompanyData(allCompaniesData as Company[]);
      const companyByOwnerData = allCompaniesData.filter((owner) => owner.companyOwnerId === user.userId)

      setCompanyDataByOwner(companyByOwnerData as Company[])
      setFreeDemoCapacityMet(companyByOwnerData.length >= MAX_NUMBER_COMPANY_FREE_DEMO)
      companyByOwnerData.length > 0 ? setIsAddCompanyModelOpen(false) : setIsAddCompanyModelOpen(true)

      if (companyByOwnerData.length > 0) {
        const earliestDate = getEarliestDate(companyByOwnerData);
        const today = new Date();
        const formattedToday = formatDate(today);
        setIsExpired(isDateAfter(earliestDate, formattedToday));
        setWillExpire(willExpireWithinAWeek(earliestDate, formattedToday));
        setCompanyExpirationData(getEarliestDateAndCompany(companyByOwnerData))
      }
    } catch (error) {
      console.error("error :>> ", error);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCompanyOwnerData = async () => {
    setIsLoading(true);
    try {
      const companyOwnerResponse = await client.graphql({
        query: listCompanyOwnersQuery,
      });
      const allCompanyOwnersData =
        companyOwnerResponse?.data?.listCompanyOwners?.items

      const currentCompanyOwner = allCompanyOwnersData.find((owner) => owner.id === user.userId)

      if (currentCompanyOwner) {
        setSubscriptionType(currentCompanyOwner.subscriptionType)
        setIsFreeDemo(currentCompanyOwner.subscriptionType === "free-demo")
        setPausedSubscription(currentCompanyOwner.subscriptionType === "paused")
        const currentDate = new Date();
        const newExpirationDate = new Date(currentDate);
        newExpirationDate.setDate(currentDate.getDate() + 30);
        setExpirationDate(newExpirationDate.toLocaleString());
        if (currentCompanyOwner.subscriptionType === "free-demo") {
          setMaxNumberUsers(4)
          setMaxNumberEquipment(4)
        } else if (currentCompanyOwner.subscriptionType === "paid-limited-access") {
          setMaxNumberUsers(10)
          setMaxNumberEquipment(10)
        }
      } else {
        setPausedSubscription(true)
      }
    } catch (error) {
      console.error("error :>> ", error);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    if (user) {
      getCompanyData()
      getCompanyOwnerData();
    }
  }, []);

  const deleteCompanyHandler = async () => {
    if (companyToDelete) {
      try {
        await client.graphql({
          query: deleteCompanyMutation,
          variables: { input: { id: companyToDelete.id } },
        });
        setCompanyToDelete(null);
        getCompanyData();
      } catch (error) {
        console.error("error :>> ", error);
        Sentry.captureException(error);
      }
    }
  };

  const handleAddCompanyFormSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    try {
      const ownerId = user.userId
      const resizedLogo =
        companyLogoInForm.length > 0 ? (await resizeLogo(companyLogoInForm[0])) as string : "";

      const input: CreateCompanyInput = {
        name: companyNameInForm,
        type: companyTypeInForm,
        logo: resizedLogo,
        description: companyDescriptionInForm,
        subscriptionType: subscriptionType,
        companyOwnerId: ownerId,
        subscriptionExpiration: expirationDate,
        maxNumberUsers: maxNumberUsers,
        maxNumberEquipment: maxNumberEquipment
      }

      const response = await client.graphql({
        query: createCompanyMutation,
        variables: { input },
      });
      getCompanyData();
      setCompanyNameInForm("");
      setCompanyTypeInForm('')
      setCompanyDescriptionInForm('')
      setCompanyLogoInForm([])
    } catch (error) {
      console.error("error :>> ", error);
      Sentry.captureException(error);
    } finally {
      setIsAddCompanyModelOpen(false);
    }
  };

  const handleEditFormSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    try {
      const resizedLogo =
        companyLogoInForm.length > 0 ? (await resizeLogo(companyLogoInForm[0])) as string : companiesToEdit[0].logo;

      setIsCreating(true)
      const input: UpdateCompanyInput = {
        id: companiesToEdit[0].id,
        name: companyNameInForm.trim() ? companyNameInForm.trim() : companiesToEdit[0].name,
        type: companyTypeInForm.trim() ? companyTypeInForm.trim() : companiesToEdit[0].type,
        logo: resizedLogo,
        description: companyDescriptionInForm ? companyDescriptionInForm.trim() : companiesToEdit[0].description,
      }
      await client.graphql({
        query: updateCompanyMutation,
        variables: { input },
      });
      await getCompanyData();
      setCompanyNameInForm("");
      setCompanyTypeInForm('')
      setCompanyDescriptionInForm('')
      setCompanyLogoInForm([])
    } catch (error) {
      console.error("error :>> ", error);
      Sentry.captureException(error);
    } finally {
      setIsEditCompanyModelOpen(false);
      setIsCreating(false)
    }
  };


  const handleLocalStorageSaving = async (item: Company) => {
    // console.log("item", item)
    localStorage.setItem("storedCompanyId", JSON.stringify(item.id))
    localStorage.setItem("storedCompanySubscriptionType", JSON.stringify(item.subscriptionType))
    localStorage.setItem("storedCompanyName", JSON.stringify(item.name))
  }

  return (
    <>
      <SpaceBetween direction="vertical" size="l">
        <Table
          header={
            <Header
              variant="h2"
              actions={
                <Button onClick={() => setIsAddCompanyModelOpen(true)} disabled={pausedSubscription || isExpired || (isFreeDemo && freeDemoCapacityMet)}>
                  Add a Company
                </Button>
              }
            >
              {pausedSubscription || isExpired ? (
                <Flashbar items={[{
                  type: "error",
                  dismissible: false,
                  content: (
                    <>
                      <Link color="inverted" href="/manage/subscription">
                        Resume Subscription to Manage: {companyExpirationData.name}
                      </Link>
                      .
                    </>
                  ),
                  id: "expired message"
                }]}
                />

              ) : !isFreeDemo && willExpire ?
                <Flashbar items={[{
                  type: "warning",
                  dismissible: false,
                  content: (
                    <>
                      Warning Subscription for {companyExpirationData.name} due to Expire on  {" "}
                      <Link color="inverted" href="/manage/subscription">
                        <strong style={{ marginTop: '10px' }} > {companyExpirationData.subscriptionExpiration}</strong>
                      </Link>
                      .
                    </>
                  ),
                  id: "warning message"
                }]}
                />
                : isFreeDemo && freeDemoCapacityMet && (
                  <Flashbar items={[{
                    type: "error",
                    dismissible: false,
                    content: (
                      <>
                        Paid Subscription Required to Add Additional Company {" "}
                        <Link color="inverted" href="/manage/subscription">
                          Upgrade Here
                        </Link>
                        .
                      </>
                    ),
                    id: "free demo message"
                  }]}
                  />
                )}
            </Header>
          }
          columnDisplay={[
            {
              id: "name",
              visible: true,
            },
            {
              id: "subscriptionExpiration",
              visible: true,
            },
            {
              id: "edit",
              visible: true,
            },
            {
              id: "delete",
              visible: true,
            },

          ]}
          columnDefinitions={[
            {
              id: "name",
              header: "Name",
              cell: (item) =>
                <Link
                  onFollow={() => {
                    const today = new Date();
                    const formattedToday = formatDate(today);
                    const isAfter = isDateAfter(item.subscriptionExpiration as string, formattedToday);
                    const subscriptionType = item.subscriptionType
                    if (subscriptionType !== "paused" && !isAfter) {
                      setCompany(item);
                      navigate("/company")
                      handleLocalStorageSaving(item)
                    } else {
                      setIsSubscriptionExpiredModalOpen(true)
                    }
                    return false;
                  }}
                >
                  {item.name}
                </Link>
            },
            {
              id: "subscriptionExpiration",
              header: "Expiration",
              cell: (item) =>
                <Box>
                  {/* {item.subscriptionExpiration?.split(",")[0]} */}
                  {isDateInFuture(item.subscriptionExpiration.split(",")[0]) ? willExpireWithinAWeek(item.subscriptionExpiration.split(",")[0], formatDate(new Date())) ? <div >
                    <p >
                      <span style={{ marginRight: "22px" }} >
                        <Icon variant="warning" name="status-warning" />
                      </span>
                      {item.subscriptionExpiration?.split(",")[0]}
                    </p>
                  </div> :
                    <div >
                      <p >
                        <span style={{ marginRight: "22px" }} >
                          <Icon variant="success" name="status-positive" />
                        </span>
                        {item.subscriptionExpiration?.split(",")[0]}
                      </p>
                    </div>
                    : <div >
                      <p >
                        <span style={{ marginRight: "22px" }} >
                          <Icon variant="error" name="status-stopped" />
                        </span>
                        {item.subscriptionExpiration?.split(",")[0]}
                      </p>
                    </div>}
                </Box>
            },
            {
              id: "edit",
              header: "Edit",
              cell: (item) =>
                <Button
                  variant="icon"
                  iconName="edit"
                  loading={isCreating}
                  onClick={async () => {
                    const resizedBlob = base64ToBlob(item.logo as string, "image/jpeg")
                    if (resizedBlob) {
                      const resizedFile = new File([resizedBlob], "Company Logo" as string, { type: "image/jepg" })
                      setCompanyLogoInForm([resizedFile])
                    } else setCompanyLogoInForm([])
                    setCompaniesToEdit([item]);
                    setCompanyNameInForm(item.name)
                    setCompanyTypeInForm(item.type ? item.type : "")
                    setCompanyDescriptionInForm(item.description ? item.description : "")
                    setIsEditCompanyModelOpen(true)
                  }}
                />
            },
            {
              id: "delete",
              header: "Delete",
              cell: (item) => (
                <Button
                  variant="icon"
                  iconName="remove"
                  onClick={async () => {
                    setCompanyToDelete(item);
                  }}
                />
              ),
            },
          ]}
          empty={<Box textAlign="center">No Companies</Box>}
          items={companyDataByOwner}
          loading={isLoading}
          loadingText="Loading..."
          trackBy="id"
        // variant="embedded"
        />
      </SpaceBetween >

      {/* add company modal */}
      < Modal
        visible={isAddCompanyModelOpen}
        onDismiss={() => setIsAddCompanyModelOpen(false)}
      >
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={() => setIsAddCompanyModelOpen(false)}>
                Cancel
              </Button>
              <Button
                onClick={handleAddCompanyFormSubmit}
                variant="primary"
                formAction="submit"
                disabled={!companyNameInForm.trim() || !companyTypeInForm.trim() || !companyDescriptionInForm.trim() || !companyLogoInForm}
              >
                Submit
              </Button>
            </SpaceBetween>
          }>
          <SpaceBetween direction="vertical" size="l">
            <SpaceBetween direction="vertical" size="l">
              <FormField label="Company Name">
                <Input
                  value={companyNameInForm}
                  placeholder="e.g. Basin Rock"
                  onChange={({ detail }) => setCompanyNameInForm(detail.value)}
                />
              </FormField>
              <FormField label="Company Type">
                <Input
                  value={companyTypeInForm}
                  placeholder="e.g. Construction"
                  onChange={({ detail }) => setCompanyTypeInForm(detail.value)}
                />
              </FormField>
              <FormField label="Company Logo">
                <FileUpload
                  accept="image/jpeg, image/jpg, image/png"
                  onChange={({ detail }) => setCompanyLogoInForm(detail.value)}
                  value={companyLogoInForm as readonly File[]}
                  i18nStrings={{
                    uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                    dropzoneText: (e) =>
                      e ? "Drop files to upload" : "Drop file to upload",
                    removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                    limitShowFewer: "Show fewer files",
                    limitShowMore: "Show more files",
                    errorIconAriaLabel: "Error",
                  }}
                  showFileLastModified
                  showFileSize
                  showFileThumbnail
                  tokenLimit={1}
                  constraintText=".jpg, .jpeg, or .png file"
                />
              </FormField>
              <FormField label="Company Description">
                <Textarea
                  value={companyDescriptionInForm}
                  placeholder="e.g. Company Blurb"
                  onChange={({ detail }) => setCompanyDescriptionInForm(detail.value)}
                />
              </FormField>
            </SpaceBetween>

          </SpaceBetween>
        </Form>
      </Modal >

      {/* edit company modal */}
      < Modal
        visible={isEditCompanyModelOpen}
        onDismiss={() => {
          setIsEditCompanyModelOpen(false)
          setCompaniesToEdit([])
        }}
      >
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={() => {
                setIsEditCompanyModelOpen(false)
                setCompaniesToEdit([])
              }}>
                Cancel
              </Button>
              <Button
                onClick={handleEditFormSubmit}
                variant="primary"
                formAction="submit"
                disabled={!companyNameInForm.trim()}
              >
                Submit
              </Button>
            </SpaceBetween>
          }>
          <SpaceBetween direction="vertical" size="l">
            <SpaceBetween direction="vertical" size="l">
              <FormField label="Company Name">
                <Input
                  value={companyNameInForm}
                  onChange={({ detail }) => setCompanyNameInForm(detail.value)}
                />
              </FormField>
              <FormField label="Company Type">
                <Input
                  value={companyTypeInForm}
                  onChange={({ detail }) => setCompanyTypeInForm(detail.value)}
                />
              </FormField>
              <FormField label="Company Logo">
                <FileUpload
                  accept="image/jpeg, image/jpg, image/png"
                  value={companyLogoInForm as File[]}
                  onChange={({ detail }) => setCompanyLogoInForm(detail.value)}
                  i18nStrings={{
                    uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                    dropzoneText: (e) =>
                      e ? "Drop files to upload" : "Drop file to upload",
                    removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                    limitShowFewer: "Show fewer files",
                    limitShowMore: "Show more files",
                    errorIconAriaLabel: "Error",
                  }}
                  showFileLastModified
                  showFileSize
                  showFileThumbnail
                  tokenLimit={1}
                  constraintText=".jpg, .jpeg, or .png file"
                />
              </FormField>
              <FormField label="Company Description">
                <Textarea
                  value={companyDescriptionInForm}
                  onChange={({ detail }) => setCompanyDescriptionInForm(detail.value)}
                />
              </FormField>
            </SpaceBetween>

          </SpaceBetween>
        </Form>
      </Modal >

      <Modal
        onDismiss={() => setCompanyToDelete(null)}
        visible={!!companyToDelete?.id}
        header={`Remove ${companyToDelete?.name}`}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={() => setCompanyToDelete(null)}>
                No
              </Button>
              <Button
                // loading={isDeleting}
                loadingText="Removing Company..."
                variant="primary"
                onClick={deleteCompanyHandler}
              >
                Yes
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        You are about to remove {companyToDelete?.name}. Are you sure?
      </Modal>

      <Modal
        onDismiss={() => setIsSubscriptionExpiredModalOpen(false)}
        visible={isSubscriptionExpiredModalOpen}
        header={`Subscription Expired`}
      >
        <SpaceBetween size={"m"}>

          Company Management Not Available until Subscription is Renewed
          <Link href="/manage/subscription">

            Click Here To Manage Subscription
          </Link>
        </SpaceBetween>
      </Modal>
    </>
  );
};
