import { Box, Header, SpaceBetween } from "@cloudscape-design/components";
import { CompanyAdmin } from "../components/CompanyAdmin";

export const Admin = (): JSX.Element => {
  return (
    <Box>
      <SpaceBetween size="m">

        <Header variant="h1">Company Administration</Header>
        <CompanyAdmin />
      </SpaceBetween>
    </Box>
  );
};
