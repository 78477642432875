/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateCompanyOwner = /* GraphQL */ `subscription OnCreateCompanyOwner(
  $filter: ModelSubscriptionCompanyOwnerFilterInput
  $owner: String
) {
  onCreateCompanyOwner(filter: $filter, owner: $owner) {
    id
    email
    name
    subscriptionType
    role
    permissions
    companies {
      items {
        id
        name
        type
        logo
        description
        subscriptionType
        subscriptionExpiration
        maxNumberUsers
        maxNumberEquipment
        companyOwnerId
        numberOfUsers
        createdAt
        updatedAt
        companyOwnerCompaniesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    siteAdmin
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCompanyOwnerSubscriptionVariables,
  APITypes.OnCreateCompanyOwnerSubscription
>;
export const onUpdateCompanyOwner = /* GraphQL */ `subscription OnUpdateCompanyOwner(
  $filter: ModelSubscriptionCompanyOwnerFilterInput
  $owner: String
) {
  onUpdateCompanyOwner(filter: $filter, owner: $owner) {
    id
    email
    name
    subscriptionType
    role
    permissions
    companies {
      items {
        id
        name
        type
        logo
        description
        subscriptionType
        subscriptionExpiration
        maxNumberUsers
        maxNumberEquipment
        companyOwnerId
        numberOfUsers
        createdAt
        updatedAt
        companyOwnerCompaniesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    siteAdmin
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCompanyOwnerSubscriptionVariables,
  APITypes.OnUpdateCompanyOwnerSubscription
>;
export const onDeleteCompanyOwner = /* GraphQL */ `subscription OnDeleteCompanyOwner(
  $filter: ModelSubscriptionCompanyOwnerFilterInput
  $owner: String
) {
  onDeleteCompanyOwner(filter: $filter, owner: $owner) {
    id
    email
    name
    subscriptionType
    role
    permissions
    companies {
      items {
        id
        name
        type
        logo
        description
        subscriptionType
        subscriptionExpiration
        maxNumberUsers
        maxNumberEquipment
        companyOwnerId
        numberOfUsers
        createdAt
        updatedAt
        companyOwnerCompaniesId
        owner
        __typename
      }
      nextToken
      __typename
    }
    siteAdmin
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCompanyOwnerSubscriptionVariables,
  APITypes.OnDeleteCompanyOwnerSubscription
>;
export const onCreateCompany = /* GraphQL */ `subscription OnCreateCompany(
  $filter: ModelSubscriptionCompanyFilterInput
  $owner: String
) {
  onCreateCompany(filter: $filter, owner: $owner) {
    id
    name
    users {
      items {
        id
        name
        email
        domain
        role
        permissions
        discrepancyNotification
        discrepancyNotificationTypes
        cognitoId
        companyId
        createdAt
        updatedAt
        companyUsersId
        owner
        __typename
      }
      nextToken
      __typename
    }
    type
    logo
    description
    subscriptionType
    subscriptionExpiration
    maxNumberUsers
    maxNumberEquipment
    companyOwnerId
    numberOfUsers
    createdAt
    updatedAt
    companyOwnerCompaniesId
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCompanySubscriptionVariables,
  APITypes.OnCreateCompanySubscription
>;
export const onUpdateCompany = /* GraphQL */ `subscription OnUpdateCompany(
  $filter: ModelSubscriptionCompanyFilterInput
  $owner: String
) {
  onUpdateCompany(filter: $filter, owner: $owner) {
    id
    name
    users {
      items {
        id
        name
        email
        domain
        role
        permissions
        discrepancyNotification
        discrepancyNotificationTypes
        cognitoId
        companyId
        createdAt
        updatedAt
        companyUsersId
        owner
        __typename
      }
      nextToken
      __typename
    }
    type
    logo
    description
    subscriptionType
    subscriptionExpiration
    maxNumberUsers
    maxNumberEquipment
    companyOwnerId
    numberOfUsers
    createdAt
    updatedAt
    companyOwnerCompaniesId
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCompanySubscriptionVariables,
  APITypes.OnUpdateCompanySubscription
>;
export const onDeleteCompany = /* GraphQL */ `subscription OnDeleteCompany(
  $filter: ModelSubscriptionCompanyFilterInput
  $owner: String
) {
  onDeleteCompany(filter: $filter, owner: $owner) {
    id
    name
    users {
      items {
        id
        name
        email
        domain
        role
        permissions
        discrepancyNotification
        discrepancyNotificationTypes
        cognitoId
        companyId
        createdAt
        updatedAt
        companyUsersId
        owner
        __typename
      }
      nextToken
      __typename
    }
    type
    logo
    description
    subscriptionType
    subscriptionExpiration
    maxNumberUsers
    maxNumberEquipment
    companyOwnerId
    numberOfUsers
    createdAt
    updatedAt
    companyOwnerCompaniesId
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCompanySubscriptionVariables,
  APITypes.OnDeleteCompanySubscription
>;
export const onCreateUser = /* GraphQL */ `subscription OnCreateUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onCreateUser(filter: $filter, owner: $owner) {
    id
    name
    email
    domain
    role
    permissions
    discrepancyNotification
    discrepancyNotificationTypes
    cognitoId
    companyId
    createdAt
    updatedAt
    companyUsersId
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSubscriptionVariables,
  APITypes.OnCreateUserSubscription
>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onUpdateUser(filter: $filter, owner: $owner) {
    id
    name
    email
    domain
    role
    permissions
    discrepancyNotification
    discrepancyNotificationTypes
    cognitoId
    companyId
    createdAt
    updatedAt
    companyUsersId
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSubscriptionVariables,
  APITypes.OnUpdateUserSubscription
>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onDeleteUser(filter: $filter, owner: $owner) {
    id
    name
    email
    domain
    role
    permissions
    discrepancyNotification
    discrepancyNotificationTypes
    cognitoId
    companyId
    createdAt
    updatedAt
    companyUsersId
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSubscriptionVariables,
  APITypes.OnDeleteUserSubscription
>;
export const onCreateForm = /* GraphQL */ `subscription OnCreateForm(
  $filter: ModelSubscriptionFormFilterInput
  $owner: String
) {
  onCreateForm(filter: $filter, owner: $owner) {
    id
    name
    companyId
    noMachineHours
    formData
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFormSubscriptionVariables,
  APITypes.OnCreateFormSubscription
>;
export const onUpdateForm = /* GraphQL */ `subscription OnUpdateForm(
  $filter: ModelSubscriptionFormFilterInput
  $owner: String
) {
  onUpdateForm(filter: $filter, owner: $owner) {
    id
    name
    companyId
    noMachineHours
    formData
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFormSubscriptionVariables,
  APITypes.OnUpdateFormSubscription
>;
export const onDeleteForm = /* GraphQL */ `subscription OnDeleteForm(
  $filter: ModelSubscriptionFormFilterInput
  $owner: String
) {
  onDeleteForm(filter: $filter, owner: $owner) {
    id
    name
    companyId
    noMachineHours
    formData
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFormSubscriptionVariables,
  APITypes.OnDeleteFormSubscription
>;
export const onCreateEquipment = /* GraphQL */ `subscription OnCreateEquipment($filter: ModelSubscriptionEquipmentFilterInput) {
  onCreateEquipment(filter: $filter) {
    id
    name
    companyId
    formId
    equipment {
      id
      name
      machineHours
      lastServiceHours
      __typename
    }
    machineHours
    lastServiceHours
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateEquipmentSubscriptionVariables,
  APITypes.OnCreateEquipmentSubscription
>;
export const onUpdateEquipment = /* GraphQL */ `subscription OnUpdateEquipment($filter: ModelSubscriptionEquipmentFilterInput) {
  onUpdateEquipment(filter: $filter) {
    id
    name
    companyId
    formId
    equipment {
      id
      name
      machineHours
      lastServiceHours
      __typename
    }
    machineHours
    lastServiceHours
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateEquipmentSubscriptionVariables,
  APITypes.OnUpdateEquipmentSubscription
>;
export const onDeleteEquipment = /* GraphQL */ `subscription OnDeleteEquipment($filter: ModelSubscriptionEquipmentFilterInput) {
  onDeleteEquipment(filter: $filter) {
    id
    name
    companyId
    formId
    equipment {
      id
      name
      machineHours
      lastServiceHours
      __typename
    }
    machineHours
    lastServiceHours
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteEquipmentSubscriptionVariables,
  APITypes.OnDeleteEquipmentSubscription
>;
export const onCreateDiscrepancy = /* GraphQL */ `subscription OnCreateDiscrepancy(
  $filter: ModelSubscriptionDiscrepancyFilterInput
  $owner: String
) {
  onCreateDiscrepancy(filter: $filter, owner: $owner) {
    id
    companyId
    equipmentId
    unitId
    questionIndex
    equipmentName
    initialStatus
    status
    log
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDiscrepancySubscriptionVariables,
  APITypes.OnCreateDiscrepancySubscription
>;
export const onUpdateDiscrepancy = /* GraphQL */ `subscription OnUpdateDiscrepancy(
  $filter: ModelSubscriptionDiscrepancyFilterInput
  $owner: String
) {
  onUpdateDiscrepancy(filter: $filter, owner: $owner) {
    id
    companyId
    equipmentId
    unitId
    questionIndex
    equipmentName
    initialStatus
    status
    log
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDiscrepancySubscriptionVariables,
  APITypes.OnUpdateDiscrepancySubscription
>;
export const onDeleteDiscrepancy = /* GraphQL */ `subscription OnDeleteDiscrepancy(
  $filter: ModelSubscriptionDiscrepancyFilterInput
  $owner: String
) {
  onDeleteDiscrepancy(filter: $filter, owner: $owner) {
    id
    companyId
    equipmentId
    unitId
    questionIndex
    equipmentName
    initialStatus
    status
    log
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDiscrepancySubscriptionVariables,
  APITypes.OnDeleteDiscrepancySubscription
>;
export const onCreateArchivedDiscrepancy = /* GraphQL */ `subscription OnCreateArchivedDiscrepancy(
  $filter: ModelSubscriptionArchivedDiscrepancyFilterInput
  $owner: String
) {
  onCreateArchivedDiscrepancy(filter: $filter, owner: $owner) {
    id
    companyId
    equipmentId
    unitId
    questionIndex
    equipmentName
    initialStatus
    status
    log
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateArchivedDiscrepancySubscriptionVariables,
  APITypes.OnCreateArchivedDiscrepancySubscription
>;
export const onUpdateArchivedDiscrepancy = /* GraphQL */ `subscription OnUpdateArchivedDiscrepancy(
  $filter: ModelSubscriptionArchivedDiscrepancyFilterInput
  $owner: String
) {
  onUpdateArchivedDiscrepancy(filter: $filter, owner: $owner) {
    id
    companyId
    equipmentId
    unitId
    questionIndex
    equipmentName
    initialStatus
    status
    log
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateArchivedDiscrepancySubscriptionVariables,
  APITypes.OnUpdateArchivedDiscrepancySubscription
>;
export const onDeleteArchivedDiscrepancy = /* GraphQL */ `subscription OnDeleteArchivedDiscrepancy(
  $filter: ModelSubscriptionArchivedDiscrepancyFilterInput
  $owner: String
) {
  onDeleteArchivedDiscrepancy(filter: $filter, owner: $owner) {
    id
    companyId
    equipmentId
    unitId
    questionIndex
    equipmentName
    initialStatus
    status
    log
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteArchivedDiscrepancySubscriptionVariables,
  APITypes.OnDeleteArchivedDiscrepancySubscription
>;
export const onCreateReport = /* GraphQL */ `subscription OnCreateReport($filter: ModelSubscriptionReportFilterInput) {
  onCreateReport(filter: $filter) {
    id
    companyId
    equipmentId
    unitId
    unitName
    equipmentName
    userId
    name
    userData
    userLocation
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReportSubscriptionVariables,
  APITypes.OnCreateReportSubscription
>;
export const onUpdateReport = /* GraphQL */ `subscription OnUpdateReport($filter: ModelSubscriptionReportFilterInput) {
  onUpdateReport(filter: $filter) {
    id
    companyId
    equipmentId
    unitId
    unitName
    equipmentName
    userId
    name
    userData
    userLocation
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReportSubscriptionVariables,
  APITypes.OnUpdateReportSubscription
>;
export const onDeleteReport = /* GraphQL */ `subscription OnDeleteReport($filter: ModelSubscriptionReportFilterInput) {
  onDeleteReport(filter: $filter) {
    id
    companyId
    equipmentId
    unitId
    unitName
    equipmentName
    userId
    name
    userData
    userLocation
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReportSubscriptionVariables,
  APITypes.OnDeleteReportSubscription
>;
export const onCreateErrors = /* GraphQL */ `subscription OnCreateErrors($filter: ModelSubscriptionErrorsFilterInput) {
  onCreateErrors(filter: $filter) {
    id
    errors
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateErrorsSubscriptionVariables,
  APITypes.OnCreateErrorsSubscription
>;
export const onUpdateErrors = /* GraphQL */ `subscription OnUpdateErrors($filter: ModelSubscriptionErrorsFilterInput) {
  onUpdateErrors(filter: $filter) {
    id
    errors
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateErrorsSubscriptionVariables,
  APITypes.OnUpdateErrorsSubscription
>;
export const onDeleteErrors = /* GraphQL */ `subscription OnDeleteErrors($filter: ModelSubscriptionErrorsFilterInput) {
  onDeleteErrors(filter: $filter) {
    id
    errors
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteErrorsSubscriptionVariables,
  APITypes.OnDeleteErrorsSubscription
>;
