import { Box, SpaceBetween } from "@cloudscape-design/components";
export const Footer = () => (
  <footer className="app-footer hide-from-printer">
    <SpaceBetween direction="vertical" size="s">
      <Box variant="h5" textAlign="center" margin="s" padding="s">
        &copy; {new Date().getFullYear()} -{" "}
        Equipmentinspectionforms.com
      </Box>
    </SpaceBetween>
  </footer>
);
