import { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";

import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import {
    createUser as createUserMutation,
    deleteUser as deleteUserMutation,
    updateUser as updateUserMutation,
} from "../graphql/mutations";
import { listCompanies, usersByCompanyId } from "../graphql/queries";
import { onUpdateUser } from "../graphql/subscriptions";
import {
    Badge,
    Box,
    BreadcrumbGroup,
    Button,
    Form,
    FormField,
    Header,
    Input,
    Modal,
    Multiselect,
    SpaceBetween,
    Table
} from "@cloudscape-design/components";
import { useUserContext } from "../contexts/UserContext";
import {
    ALL_USER_PERMISSIONS,
    DEFAULT_ADMIN_PERMISSIONS,
    DEFAULT_USER_PERMISSIONS,
    NETWORK_DOMAIN,
} from "../utils/constants";
import { CreateUserInput, UpdateUserInput, User } from "../API";
import { iCompanyProps } from "../types";
import { Button as AWSButton, useAuthenticator } from "@aws-amplify/ui-react";

const client = generateClient({ authMode: "userPool" });

export const UsersContent = ({
    hideBreadCrumbs = false,
    parent,
    role,
    selfPath = '',
}: iCompanyProps): JSX.Element => {

    const { user } = useAuthenticator((context) => [
        context.route,
        context.user,
    ]);
    const { company, companyUser, setCompanyUser } = useUserContext();
    const [users, setUsers] = useState<User[]>();
    const [deleteUser, setDeleteUser] = useState<User | null>(null);
    const [resetUserList, setResetUserList] = useState<string[]>([]);
    const [formEmail, setFormEmail] = useState<string>("");
    const [formName, setFormName] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<string | null>(null);
    const [freeDemoCapacityMet, setFreeDemoCapacityMet] = useState<boolean>(false)
    const [isFreeDemo, setIsFreeDemo] = useState<boolean>(false)
    const [isAddUserModelOpen, setIsAddUserModelOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const storedCompanyId = localStorage.getItem("storedCompanyId")
    const storedCompanyName = localStorage.getItem("storedCompanyName")

    const companyId = company?.id as string || JSON.parse(storedCompanyId as string)

    const companyOwnerId = company ? null : user.userId

    const [selectedPermissions, setSelectedPermissions] = useState<{ label: string; value: string }[]>(
        companyOwnerId ? DEFAULT_ADMIN_PERMISSIONS : DEFAULT_USER_PERMISSIONS
    );

    const getUsers = async () => {
        if (!companyId && !storedCompanyId) {
            return;
        }
        setIsLoading(true);
        try {
            const result = await client.graphql({
                query: usersByCompanyId,
                variables: {
                    companyId: companyId
                },
            })
            const companyResult = await client.graphql({
                query: listCompanies,
            })

            const currentCompany = companyResult.data.listCompanies.items.find((company) => company.id === companyId)

            setUsers(result.data.usersByCompanyId.items as User[]);

            setIsFreeDemo(currentCompany?.subscriptionType === "free-demo")
            setFreeDemoCapacityMet(result.data.usersByCompanyId.items.length >= (currentCompany ? currentCompany.maxNumberUsers : 99999))

        } catch (error) {
            console.log(error);
            Sentry.captureException(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!companyId) {
            navigate(parent);
            return;
        }
        getUsers();

        const updateSub = client
            .graphql({
                query: onUpdateUser,
                variables: {
                    filter: {
                        companyId: { eq: companyId },
                        role: { eq: role },
                    },
                },
            })
            .subscribe({
                next: ({ data }) => {
                    setUsers((usersInState = []) => {
                        const toUpdateIndex = usersInState.findIndex(
                            (item) => item.id === data.onUpdateUser.id
                        );

                        if (toUpdateIndex === -1) {
                            // If the user doesn't exist, treat it like an "add"
                            return [data.onUpdateUser, ...usersInState];
                        }
                        return [
                            ...usersInState.slice(0, toUpdateIndex),
                            data.onUpdateUser,
                            ...usersInState.slice(toUpdateIndex + 1),
                        ];
                    });
                    if (data.onUpdateUser.cognitoId === companyUser?.cognitoId) {
                        setCompanyUser(data.onUpdateUser);
                    }
                },
            });

        return () => {
            updateSub.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteUserHandler = async () => {
        if (!deleteUser) return;
        setIsLoading(true);
        try {
            await client.graphql({
                query: deleteUserMutation,
                variables: {
                    input: {
                        id: deleteUser.id,
                    },
                },
            });
            setDeleteUser(null);
            await getUsers();
        } catch (error) {
            console.log(error);
            Sentry.captureException(error);
        } finally {
            setIsLoading(false);
        }
    };

    const resetUserHandler = async (item: User) => {
        try {
            await client.graphql({
                query: deleteUserMutation,
                variables: {
                    input: {
                        id: item.id,
                    },
                },
            });
            await client.graphql({
                query: createUserMutation,
                variables: {
                    input: {
                        email: item.email.toLowerCase(),
                        domain: NETWORK_DOMAIN,
                        name: item.name,
                        companyId: item.companyId,
                        role: item.role,
                        permissions: item.permissions,
                    },
                },
            });
        } catch (error) {
            console.log(error);
            Sentry.captureException(error);
        }
    };

    const handleFormSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        try {
            if (!isEditing) {
                setIsCreating(true);
                // TODO: Add separate edit and create flows here
                // TODO: figure out how to coniditonally render and display the correct role, right now it's defaulting to super-admin for users created by a super-admin and I'm not sure why
                const input: CreateUserInput = {
                    email: formEmail.trim().toLowerCase(),
                    domain: NETWORK_DOMAIN,
                    name: formName.trim(),
                    companyId: companyId,
                    role: "admin",
                    permissions: JSON.stringify(
                        selectedPermissions.map((item) => item.value)
                    ),
                }
                await client.graphql({
                    query: createUserMutation,
                    variables: { input },
                });
            } else {
                const input: UpdateUserInput = {
                    id: isEditing,
                    email: formEmail.trim().toLowerCase(),
                    domain: NETWORK_DOMAIN,
                    name: formName.trim(),
                    companyId: companyId,
                    role,
                    permissions: JSON.stringify(
                        selectedPermissions.map((item) => item.value)
                    ),
                };
                await client.graphql({
                    query: updateUserMutation,
                    variables: { input },
                });
            }
            await getUsers();
            setFormEmail("");
            setFormName("");
            setSelectedPermissions(role === "super-admin" ? DEFAULT_ADMIN_PERMISSIONS : DEFAULT_USER_PERMISSIONS);
        } catch (error) {
            console.log(error);
            Sentry.captureException(error);
        } finally {
            setIsEditing(null);
            setIsCreating(false);
            setIsAddUserModelOpen(false);
        }
    };

    const handleUpgradeClick = () => {
        navigate("/manage/subscription", { replace: true })
    }

    return (
        <SpaceBetween direction="vertical" size="l">
            {!hideBreadCrumbs && !!storedCompanyName && (
                <div className="hide-from-printer">
                    <BreadcrumbGroup
                        onFollow={(event) => {
                            event.preventDefault();
                            navigate(event.detail.href);
                        }}
                        items={[
                            { text: "Dashboard", href: "/company" },
                            {
                                text: storedCompanyName || '',
                                href: selfPath,
                            },
                        ]}
                        ariaLabel="Breadcrumbs"
                    />
                </div>
            )}
            <Table
                // variant="embedded"
                header={
                    <Header
                        variant="h2"
                        counter={`(${users?.length || 0})`}
                        actions={
                            <Button onClick={() => setIsAddUserModelOpen(true)}
                                disabled={isFreeDemo && freeDemoCapacityMet}>
                                Add a User
                            </Button>
                        }
                    >
                        {isFreeDemo && freeDemoCapacityMet && (
                            role === "super-admin" ? (
                                <SpaceBetween direction="horizontal" size="m">
                                    <p style={{ color: 'red', marginTop: '10px' }}>
                                        Upgrade to add More Users
                                    </p>
                                    <AWSButton
                                        backgroundColor="green"
                                        color="white"
                                        loadingText=""
                                        onClick={handleUpgradeClick}
                                    >
                                        Upgrade Subscription
                                    </AWSButton>
                                </SpaceBetween>
                            ) : (
                                <p style={{ color: 'red', marginTop: '10px' }}>
                                    Contact Admin to Upgrade Subscription
                                </p>
                            )
                        )}
                        Users
                    </Header>
                }
                wrapLines
                columnDisplay={[
                    {
                        id: "user",
                        visible: true,
                    },
                    {
                        id: "email",
                        visible: true,
                    },
                    {
                        id: "role",
                        visible: true,
                    },
                    {
                        id: "edit",
                        visible: true,
                    },
                    {
                        id: "confirmed",
                        visible: true,
                    },
                    {
                        id: "resend",
                        visible: users?.some((user) => !user.cognitoId) || false,
                    },
                    {
                        id: "delete",
                        visible: true,
                    },
                ]}
                columnDefinitions={[
                    {
                        id: "user",
                        header: "Name",
                        cell: (item) => item.name || "--",
                    },
                    {
                        id: "email",
                        header: "Email",
                        cell: (item) => item.email,
                    },
                    {
                        id: "role",
                        header: "Role/Permissions",
                        cell: (item) => {
                            if (item.permissions) {
                                const permissions: string[] = [];
                                ALL_USER_PERMISSIONS.forEach((group) => {
                                    group.options.forEach((option) => {
                                        if (item?.permissions?.includes(option.value)) {
                                            permissions.push(option.label);
                                        }
                                    });
                                });
                                return permissions.join(", ");
                            }
                            return item?.role?.toUpperCase();
                        },
                    },
                    {
                        id: "edit",
                        header: "Edit",
                        cell: (item) => {
                            return (
                                <Button
                                    variant="icon"
                                    iconName="edit"
                                    onClick={() => {
                                        const existingPermissions: { label: string; value: string; }[] = [];
                                        if (item.permissions) {
                                            ALL_USER_PERMISSIONS.forEach((group) => {
                                                group.options.forEach((option) => {
                                                    if (item?.permissions?.includes(option.value)) {
                                                        existingPermissions.push(option);
                                                    }
                                                });
                                            });
                                        }
                                        setFormEmail(item.email);
                                        setFormName(item.name);
                                        setSelectedPermissions(existingPermissions);
                                        setIsEditing(item.id);
                                        setIsAddUserModelOpen(true);
                                    }}
                                />
                            );
                        },
                    },
                    {
                        id: "confirmed",
                        header: "Confirmed",
                        cell: (item) =>
                            item.cognitoId ? (
                                <Badge color="green">Confirmed</Badge>
                            ) : (
                                <Badge color="red">Unconfirmed</Badge>
                            ),
                    },
                    {
                        id: "resend",
                        header: "Resend",
                        cell: (item) =>
                            item.cognitoId || resetUserList.includes(item.id) ? (
                                <>{resetUserList.includes(item.id) && "Invite Sent"}</>
                            ) : (
                                <Button
                                    variant="icon"
                                    iconName="envelope"
                                    onClick={async () => {
                                        setResetUserList([...resetUserList, item.id]);
                                        await resetUserHandler(item);
                                    }}
                                />
                            ),
                    },
                    {
                        id: "delete",
                        header: "Delete",
                        cell: (item) =>
                            item.id !== companyUser?.id ? (
                                <Button
                                    variant="icon"
                                    iconName="remove"
                                    onClick={async () => {
                                        setDeleteUser(item);
                                    }}
                                />
                            ) : (
                                <></>
                            ),
                    },
                ]}
                items={users || []}
                empty={
                    <Box textAlign="center" color="inherit">
                        <b>No users</b>
                    </Box>
                }
                loading={isLoading}
                loadingText="Loading users..."
            />
            <Modal
                visible={isAddUserModelOpen}
                size="large"
                onDismiss={() => setIsAddUserModelOpen(false)}
            >
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => {
                                setIsEditing(null)
                                setFormEmail("");
                                setFormName("");
                                setSelectedPermissions(DEFAULT_USER_PERMISSIONS);
                                setIsAddUserModelOpen(false)
                            }}>
                                Cancel
                            </Button>
                            <Button
                                onClick={handleFormSubmit}
                                loading={isCreating}
                                variant="primary"
                                formAction="submit"
                                disabled={!formEmail.trim() || !formName.trim()}
                            >
                                Submit
                            </Button>
                        </SpaceBetween>
                    }
                >
                    <SpaceBetween direction="vertical" size="l">
                        {isEditing && <Header variant="h3">{formName}</Header>}
                        {!isEditing && (
                            <>
                                <FormField label="Name">
                                    <Input
                                        value={formName}
                                        type="text"
                                        placeholder="e.g Mark Stratton"
                                        onChange={({ detail }) => setFormName(detail.value)}
                                    />
                                </FormField>
                                <FormField label="Email">
                                    <Input
                                        value={formEmail}
                                        type="email"
                                        placeholder="e.g markstratton@gmail.com"
                                        onChange={({ detail }) => setFormEmail(detail.value)}
                                    />
                                </FormField>
                            </>
                        )}
                        <SpaceBetween direction="horizontal" size="xs">
                            <FormField label="Role & Permissions">
                                <Multiselect
                                    selectedOptions={selectedPermissions}
                                    onChange={({ detail }) =>
                                        setSelectedPermissions(detail.selectedOptions as never)
                                    }
                                    options={
                                        import.meta.env.DEV
                                            ? ALL_USER_PERMISSIONS
                                            : ALL_USER_PERMISSIONS.filter((permission) => !permission.devOnly)}
                                    expandToViewport
                                    placeholder="Select Permissions"
                                    tokenLimit={3}
                                />
                            </FormField>
                        </SpaceBetween>
                    </SpaceBetween>
                </Form>
            </Modal>
            <Modal
                onDismiss={() => setDeleteUser(null)}
                visible={!!deleteUser?.id}
                header={`Remove ${deleteUser?.name}`}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setDeleteUser(null)}>
                                No
                            </Button>
                            <Button
                                loadingText="Removing User..."
                                variant="primary"
                                onClick={deleteUserHandler}
                            >
                                Yes
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                You are about to remove {deleteUser?.name}. Are you sure?
            </Modal>
        </SpaceBetween>
    );
};