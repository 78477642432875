import { useEffect, useState } from "react";
import { Box, Header } from "@cloudscape-design/components";
import { SignInHeader } from "../components/Auth/SignInHeader/SignInHeader";
import { SignInFooter } from "../components/Auth/SignInFooter/SignInFooter";

import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router";
import { iLoginProps } from "../types";
const client = generateClient();
import { SignUpHeader } from "../components/Auth/SignUpHeader/SignUpHeader";
import { companyOwnersById, getCompany, usersByCognitoId } from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { signOut } from "aws-amplify/auth";

export const Login = ({ logIn }: iLoginProps) => {
  const { route, user } = useAuthenticator((context) => [context.route]);
  const { toForgotPassword } = useAuthenticator();
  const [validUser, setValidUser] = useState<boolean | null>(null)
  const [validOwner, setValidOwner] = useState<boolean | null>(null)
  const navigate = useNavigate();


  useEffect(() => {
    if (user) {

      queryExistanceOfOwnerOrUserInDynamo()

    }
  }, [user])

  // const from = location.state?.from?.pathname ? `${location.state?.from?.pathname}${location.state?.from?.search}` : "/";
  useEffect(() => {

    if (validOwner || validUser) {
      if (route === "authenticated") {
        navigate("/", { replace: true });
      }
    } else if (validOwner === false && validUser === false) {
      if (route === "authenticated") {
        signOut()
        navigate("/account", { replace: true });
      }
    }

  }, [validOwner, validUser, route]);


  const queryExistanceOfOwnerOrUserInDynamo = async () => {
    const ownerExists = await checkIfOwnerExists(user.userId);

    if (!ownerExists) {
      checkIfUserExists(user.userId)
    }
  };

  const checkIfOwnerExists = async (id: string): Promise<boolean> => {
    const response = await client.graphql({
      query: companyOwnersById,
      variables: { id },
    });

    setValidOwner(response.data.companyOwnersById.items.length > 0);

    return response.data.companyOwnersById.items.length > 0// Returns true if items exist
  };

  const checkIfUserExists = async (cognitoId: string) => {
    const response = await client.graphql({
      query: usersByCognitoId,
      variables: { cognitoId },
    });

    if (response.data.usersByCognitoId.items.length > 0) {
      const companyExists = await checkIfUserCompanyExists(response.data.usersByCognitoId.items[0].companyId as string)

      setValidUser(companyExists)
    }
  };

  const checkIfUserCompanyExists = async (companyId: string): Promise<boolean> => {
    const response = await client.graphql({
      query: getCompany,
      variables: { id: companyId }
    })

    return response.data.getCompany ? true : false
  }

  const components = {
    SignIn: {
      Header() {
        return <SignInHeader />;
      },
      Footer() {
        return (
          <SignInFooter
            toForgotPassword={toForgotPassword}
            forgotPasswordText="Forgot Password"
          />
        );
      },
    },
    SignUp: {
      Header() {
        console.log('header')
        return <SignUpHeader />
      },
    },
  };
  const formFields = !logIn
    ? {
      signUp: {
        name: {
          placeholder: "Enter Your Name Here",
          isRequired: true,
          label: "Name:",
        },
      },
    }
    : {
      signIn: {
        username: {
          placeholder: 'Enter Your Email Here',
          isRequired: true,
          label: 'Email:'
        },
      },
    };
  return (
    <View className="auth-wrapper centered-wrapper">
      <Box textAlign="center">
        <img
          className="centered-logo"
          src="/ei_logo.jpg"
          alt="logo"
          width="300"
        />
      </Box>
      <Authenticator
        key="signIn"
        initialState={"signIn"}
        components={components}
        formFields={formFields}
        loginMechanisms={["email"]}
        hideSignUp={logIn}
      >
        <Header>Loading...</Header>

      </Authenticator>
    </View>
  );
};
